import React, { useState, useEffect } from "react";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";
// import { useStateValue } from "../state/context";
import StepControlls from "../components/StepControlls";
import ButtonEmpty from "../components/button/ButtonEmpty";
import { jobAvailability } from '../store/actions/jobAction';
import { getParameterByName } from '../utils/formatter';
import { connect } from 'react-redux';
import Parse from 'parse';
import { optimizeImage, uploadImage } from "../utils/image.js";
import Jobin from "jobin-client";

import "../styles/service-date.css";

function ServiceAvailability(props) {
    //const [{ job }, dispatch] = useStateValue();
    const job = props.job;
    const [focused, setFocused] = useState(true);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [DateTo, setDateTo] = useState();

    useEffect(() => {
        const businessId = getParameterByName("business");
        props.jobAvailability({dateTo: null, buttonTitle: "Continuar sin fecha"});
         if (!(props.location.state && "inner" in props.location.state)){
            Jobin.Client.logOut().then(result => {
                if (result.status === "ok") window.location.href =getParameterByName("business") != "null" ?"#/?business="+ getParameterByName("business"):"#";
            });
         }
         let avalibleDay = moment(props.dateTo? props.dateTo : new Date()).add(5, "days")
         if(avalibleDay.format('dddd') == "Saturday"){
            avalibleDay.add(2, "days")
        }else if(avalibleDay.format('dddd') == "Sunday"){
            avalibleDay.add(1, "days")
        }
        setDateTo(avalibleDay)
        props.update({dateTo: avalibleDay.utc().endOf("day"), buttonTitle: "Continuar"});
        props.jobAvailability({dateTo: avalibleDay.utc().endOf("day"), buttonTitle: "Continuar"});
        showModal()
     }, []);

     const aa = () => {
        props.update({dateTo: moment().add(1, "days").utc().endOf("day"), buttonTitle: "Continuar"});
            props.jobAvailability({dateTo: moment().add(1, "days").utc().endOf("day"), buttonTitle: "Continuar"});
    };
     const clearDate = () => {
       props.update({dateTo: null, buttonTitle: "Continuar sin fecha"});
       props.jobAvailability({dateTo: null, buttonTitle: "Continuar sin fecha"});
       /* dispatch({
            type: "JOB_AVAILABILITY",
            dateTo: null,
            buttonTitle: "Continuar sin fecha"
        });*/
    };

    const onDateChangeDate = date => {
        props.update({dateTo: date.utc().startOf("day"), buttonTitle: "Continuar"});
        props.jobAvailability({dateTo: date.utc().startOf("day"), buttonTitle: "Continuar"});
        // dispatch({
        //     type: "JOB_AVAILABILITY",
        //     dateTo: date.utc().startOf("day"),
        //     buttonTitle: "Continuar"
        // });
    };

    const update = childData => {
        props.mixpanel.track("Paso 6 completado");

        // push state to parent to save the content
        props.update(childData);
    };

    const checkBusiness = async () => {
        const businessId = getParameterByName("business");
        const businessQuery = new Parse.Query("Business");
        businessQuery.include("User");
        businessQuery.equalTo("objectId", businessId);
        const business = await businessQuery.first();
        return business;
    };
    
    const showModal = async () => {
        const business = await checkBusiness();
        if (((props.currentUser && props.currentUser.has("Name")) || (business && business.get("User"))) && business && !props.checkAllowedBusiness(business.id) && !props.business.isProManager) {
            setDisabledBtn(true);
            setLoading(true);
            /*this.setState({
                disabledBtn: true,
                loading: true
            });*/
            createJob(business);
        } else if ((props.currentUser && props.currentUser.has("Name")) && business && (props.checkAllowedBusiness(business.id) || !props.business.isProManager)){
            props.showModal({ step: 8 });
        }else {
            props.mixpanel.track("Ir a log in / registro");
            props.mixpanel.track("Iniciar verificacion telefono");
            // push state to parent to save the content
            props.showModal({ step: 1 });
        }
    };

    const createJob = business => {
        //this.sendCodeJustNow();
        if(props.checkAllowedBusiness(business.id)){
            props.showModal({ step: 5 });
        }else{
            const user =
                business && business.get("User")
                    ? business.get("User")
                    : props.currentUser;

            let imgUrlPromise = [];
            if (props.jobInfo.picture.files.length > 0) {
                imgUrlPromise = props.jobInfo.picture.files.map(file => {
                    const base64Promise = optimizeImage(file);
                    return base64Promise.then(
                        base64 => {
                            // randomly generate a new file name
                            const fileName =
                                "upload-request-" +
                                Math.floor(Math.random() * 90000) +
                                10000 +
                                ".jpg";
                            return uploadImage(fileName, base64).catch(() => {
                                return undefined;
                            });
                        },
                        () => {
                            return undefined;
                        }
                    );
                });
            }

            const createJobPromise = Promise.all(imgUrlPromise).then(imgUrl => {
                props.mixpanel.alias(user.id);
                props.mixpanel.identify(props.mixpanel.get_distinct_id());
                props.mixpanel.people.set({
                    $name: user.get("Name"),
                    $phone: user.get("Phone"),
                    $email: user.get("email"),
                    "Nº de trabajos": user.get("Tasks"),
                    Nivel: user.get("Level")
                });
                props.mixpanel.track("Registro nombre completado");
            
                return Parse.Cloud.run("createJob", {
                    userId: user.id,
                    verticalId: props.jobInfo.category.categoryId,
                    verticalType: props.jobInfo.category.categoryType,
                    subCategoryId:
                        props.jobInfo.category.subCategoryId !== ""
                            ? props.jobInfo.category.subCategoryId
                            : undefined,
                    description: props.jobInfo.description.message,
                    images: imgUrl.length > 0 ? imgUrl : undefined,
                    latitud: props.jobInfo.locate.center.lat,
                    longitud: props.jobInfo.locate.center.lng,
                    priority: props.jobInfo.urgency.selectedOption,
                    fromIntegration: "web",
                    businessId: business ? business.id : undefined,
                    state: business ? "abierto" : undefined,
                    jobTypeState: props.jobTypeState
                });
            });

            createJobPromise.then(
                () => {
                    props.mixpanel.track("Registro completado");
                    props.mixpanel.track("Trabajo guardado");
                    const clickId = getParameterByName("eid");
                    if (clickId) {
                        var xhr = new XMLHttpRequest();
                        xhr.open(
                            "GET",
                            `https://eat.emmasolutions.net/postback?eid=${clickId}`,
                            false
                        );
                        xhr.send(null);
                        if (xhr.status === 200) console.log("emma postback ok");
                    }

                    /*this.setState({
                        disabledBtn: false,
                        loading: false
                    });*/

                    props.history.push({
                        pathname: "summary",
                        search: props.location.search,
                        state: {
                            inner: true
                        }
                    });
                },
                err => {
                    console.error(err,"serviceAval")
                    setDisabledBtn(false);
                    setLoading(false);
                    /*this.setState({
                        disabledBtn: false,
                        loading: false
                    });*/
                    // checks if the error is caused by no jobers or if its just an internal error
                    if (
                        err
                            .toString()
                            .split(" ")
                            .includes("NO_JOBERS")
                    )
                        props.showModal({ step: 6 });
                    else props.showModal({ step: 5 });
                }
            );
        }
    };


  

    const RenderDate = () => {
        return (
            <DayPickerSingleDateController
                onDateChange={onDateChangeDate}
                onFocusChange={() => setFocused(true)}
                focused={focused}
                date={DateTo}
                numberOfMonths={2}
                hideKeyboardShortcutsPanel={true}
            />
        );
    };

    return (
        <div id="service-date" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6" style={{display:'none'}}>
                        <h4 className="title">Fecha del servicio</h4>
                        <p>Fijar fecha de visita</p>
                        <div className="content">
                            <RenderDate />
                            {props.dateTo && (
                                <ButtonEmpty
                                    buttonText="Limpiar fecha"
                                    onClick={clearDate}
                                />
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <StepControlls
                history={props.history}
                location={props.location}
                page={props.step}
                isLastPage={true}
                disabled={disabledBtn}
                nextTrigger={null}
                //loadTrigger={state.loading}
                prev="location"
                //cState={state}
                showModal={showModal}
                loading={loading}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        dateTo: state.jobReducer.availability.dateTo,
        buttonTitle: state.jobReducer.availability.dateTo,
        jobTypeState: state.serviceReducer.service.jobType,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        jobAvailability: (data) => dispatch(jobAvailability(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAvailability);