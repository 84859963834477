import Parse from "parse";
import React from "react";

export function optimizeImage(file) {
    return new Promise((resolve, reject) => {
        if (window.FileReader) {
            // great success! All the File APIs are supported.
            let reader = new FileReader();

            reader.onloadstart = e => { };

            reader.onloadend = e => {
                let error = e.target.error;
                if (error != null) {
                    switch (error.code) {
                        case error.ENCODING_ERR:
                            reject("encoding error");
                            break;

                        case error.NOT_FOUND_ERR:
                            reject("not found error");
                            break;

                        case error.NOT_READABLE_ERR:
                            reject("not readable error");
                            break;

                        case error.SECURITY_ERR:
                            reject("security error");
                            break;

                        default:
                            reject("generic error");
                            break;
                    }
                } else {
                    // starting the image optmization process
                    let image = new Image();
                    image.onload = imageEvent => {
                        // Resize the image
                        let canvas = document.createElement("canvas"),
                            max_size = 550,
                            width = image.width,
                            height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        canvas
                            .getContext("2d")
                            .drawImage(image, 0, 0, width, height);
                        // this is the base64 img
                        let base64 = canvas.toDataURL("image/jpeg");
                        resolve(base64);
                    };
                    image.src = e.target.result;
                }
            };
            // read in the image file as a data URL.
            reader.readAsDataURL(file);
        } else reject("The File API are not fully supported in this browser.");
    });
}

export function uploadImage(fileName, base64) {
    return new Promise((resolve, reject) => {
        // creating the parse file
        let parseFile = new Parse.File(fileName, { base64: base64 });
        // uploading the parse file
        parseFile.save().then(
            result => {
                // resolve the promise and get the image url
                resolve(result._url);
            },
            err => {
                // reject the promise
                reject(err);
            }
        );
    });
}

export function selectBusinessColor(businessId) {
    let color;
    switch (businessId) {
        case "fnijgFrRYy": // Cleverea
            color = "#d2d2d2";
            break;
        case "HJkv3xWBGh": // Cubro
            color = "#d2d2d2";
            break;
        case "BUFKRUAwbD": // Ciudadela
            color = "#fe145d";
            break;
        case "pB2BciWgw5": // fintonic
            color = "#fe145d";
            break;
        case "ZcFC5W6QCB": // diglo
            color = "#004b7a";
            break;
        case "lMyRIzN79G": // Mutua
            color = "#5D9AD7";
            break;
        case "ZF5WZAbvQ6": // Bauhaus
            color = "#f4000d";
            break;
        case "kqJtDU5D9b": // Acentor
            color = "#5CB047";
            break;
        case "W8fBSZcVw5": // Pelayo
            color = "#76BA3B";
            break;
        case "2uc4nQSWfG": // gesvalt
            color = "#0075c9";
            break;

        default:
            color = "#FFE235"
    }

    return color;
}

export function selectBusinessTerms(businessId, page) {
    if ("ZF5WZAbvQ6" === businessId) {
        return (
            <div className={page === "home" ? "row-terms row-terms-home" : "row-terms"}>
                <a href={"https://www.bauhaus.es/condiciones-generales"} className={"terms"} target={"_blank"}>Términos y condiciones</a>
            </div>
        );
    }
}

export function selectBusinessIcon(item, business) {

    let url;

    switch (business) {
        case "PEc3BfMCFs": // Facilitea
            switch (item.get("Type")) {
                case "Reformas":
                    delete require.cache[require.resolve("../assets/facilitea-icons/Casco.png")];
                    url = require("../assets/facilitea-icons/Casco.png")
                    break;
                case "Climatización":
                    delete require.cache[require.resolve("../assets/facilitea-icons/Climatización.png")];
                    url = require("../assets/facilitea-icons/Climatización.png")
                    break;
                case "Carp. Metal":
                    delete require.cache[require.resolve("../assets/facilitea-icons/Metal.png")];
                    url = require("../assets/facilitea-icons/Metal.png")
                    break;
                case "Tejado":
                    delete require.cache[require.resolve("../assets/facilitea-icons/Tejado.png")];
                    url = require("../assets/facilitea-icons/Tejado.png")
                    break;
                default:
                    url = require("../assets/facilitea-icons/Casco.png")
            }
            break;
        case "VzKtdQ6MwL": // Wivai
            switch (item.get("Type")) {
                case "Reformas":
                    delete require.cache[require.resolve("../assets/wivai2/Casco.png")];
                    url = require("../assets/wivai2/Casco.png")
                    break;
                case "Climatización":
                    delete require.cache[require.resolve("../assets/wivai2/ClimatizaciónC.png")];
                    url = require("../assets/wivai2/ClimatizaciónC.png")
                    break;
                case "Carp. Metal":
                    delete require.cache[require.resolve("../assets/wivai2/MetalC.png")];
                    url = require("../assets/wivai2/MetalC.png")
                    break;
                case "Tejado":
                    delete require.cache[require.resolve("../assets/wivai2/TejadoC.png")];
                    url = require("../assets/wivai2/TejadoC.png")
                    break;
                default:
                    url = require("../assets/wivai2/Casco.png")
            }
            break;
        case "kjy2Nmbl5v": // Housell
            switch (item.get("Type")) {
                case "Manitas":
                    url = require("../assets/housell-icons/Martillo.png")
                    break;
                case "Pintor":
                    url = require("../assets/housell-icons/Pintura.png")
                    break;
                case "Fontanero":
                    url = require("../assets/housell-icons/Desatascador.png")
                    break;
                case "Albañil":
                    url = require("../assets/housell-icons/Casco.png")
                    break;
                case "Electricista":
                    url = require("../assets/housell-icons/Electricidad.png")
                    break;
                case "Reformas":
                    url = require("../assets/housell-icons/Casco.png")
                    break;
                case "Construcción":
                    url = require("../assets/housell-icons/Gancho.png")
                    break;
                case "Limpieza":
                    url = require("../assets/housell-icons/Limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/housell-icons/Sierra.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/housell-icons/Metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/housell-icons/Electrodomésticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/housell-icons/Climatización.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/housell-icons/Caja.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/housell-icons/Carrajería.png")
                    break;
                case "Jardinero":
                    url = require("../assets/housell-icons/Jardinería.png")
                    break;
                case "Antenista":
                    url = require("../assets/housell-icons/Antena.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/housell-icons/Persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/housell-icons/Cristal.png")
                    break;
                case "Suelo":
                    url = require("../assets/housell-icons/Suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/housell-icons/Tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/housell-icons/Piscina.png")
                    break;
                case "Customización":
                    url = require("../assets/housell-icons/Varita.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/housell-icons/Varita.png")
                    break;
                case "Armarios":
                    url = require("../assets/housell-icons/Martillo.png")
                    break;
                case "Instalar inodoro":
                    url = require("../assets/housell-icons/Desatascador.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/housell-icons/Martillo.png")
                    break;
                case "Revisiones":
                    url = require("../assets/housell-icons/Martillo.png")
                    break;
                case "Accesorios":
                    url = require("../assets/housell-icons/Varita.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/housell-icons/Varita.png")
                    break;
                case "Paneles Solares":
                    url = require("../assets/housell-icons/Varita.png")
                    break;
                case "Cocinas":
                    url = require("../assets/housell-icons/Cocina.png")
                    break;
                case "Baños":
                    url = require("../assets/housell-icons/Baño.png")
                    break;
                default:
                    url = require("../assets/housell-icons/Martillo.png")
            }
            break;
        case "ZF5WZAbvQ6": // Bauhaus
            switch (item.get("Type")) {
                case "Calor":
                    url = require("../assets/bauhaus-icons/" + item.get("Type") + ".png")
                    break;
                case "Pared":
                    url = require("../assets/bauhaus-icons/" + item.get("Type") + ".png")
                    break;
                case "Escaleras":
                    url = require("../assets/bauhaus-icons/" + item.get("Type") + ".png")
                    break;
                case "Puerta de paso":
                case "Puerta de garaje":
                case "Puerta de entrada":
                    url = require("../assets/bauhaus-icons/Puerta de paso.png")
                    break;
                case "Cerámica":
                    url = require("../assets/bauhaus-icons/Suelo.png")
                    break;
                case "Pintor":
                    url = require("../assets/bauhaus-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/bauhaus-icons/fontanero.png")
                    break;
                case "Electricidad":
                    url = require("../assets/bauhaus-icons/electricista.png")
                    break;
                case "Albañil":
                    url = require("../assets/bauhaus-icons/albanil.png")
                    break;
                case "Electrodomesticos":
                    url = require("../assets/bauhaus-icons/electrodomesticos.png")
                    break;
                case "Reformas":
                    url = require("../assets/bauhaus-icons/reformas.png")
                    break;
                    url = require("../assets/bauhaus-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/bauhaus-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/bauhaus-icons/limpieza.png")
                    break;
                case "Piscinas":
                    url = require("../assets/bauhaus-icons/piscina.png")
                    break;
                case "Toldos":
                    url = require("../assets/bauhaus-icons/Persianas.png")
                    break;
                case "Baño":
                case "Agua caliente":
                    url = require("../assets/bauhaus-icons/Baño.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/bauhaus-icons/carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/bauhaus-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/bauhaus-icons/Climatizacion.png")
                    break;
                case "Escaleras":
                    url = require("../assets/bauhaus-icons/Taladro.png")
                    break;
                case "Ventanas":
                    url = require("../assets/bauhaus-icons/cristalero.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/bauhaus-icons/cerrajero.png")
                    break;
                case "Jardinero":
                case "Jardín":
                case "Jardin":
                    url = require("../assets/bauhaus-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/bauhaus-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/bauhaus-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/bauhaus-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/bauhaus-icons/Suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/bauhaus-icons/Tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/bauhaus-icons/piscina.png")
                    break;
                case "Customización":
                    url = require("../assets/bauhaus-icons/customizacion.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/bauhaus-icons/Coche color.png")
                    break;
                case "Armarios":
                    url = require("../assets/bauhaus-icons/Armario.png")
                    break;
                case "Instalar inodoro":
                    url = require("../assets/bauhaus-icons/Baño.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/bauhaus-icons/Coche accesorios.png")
                    break;
                case "Revisiones":
                    url = require("../assets/bauhaus-icons/Coche duda.png")
                    break;
                case "Accesorios":
                    url = require("../assets/bauhaus-icons/customizacion.png")
                    break;
                case "Paneles Solares":
                    url = require("../assets/bauhaus-icons/Panel solar.png")
                    break;
                case "Cocinas":
                    url = require("../assets/bauhaus-icons/Cocina.png")
                    break;
                case "Baños":
                    url = require("../assets/bauhaus-icons/Baño.png")
                    break;
                case "Baterías":
                    url = require("../assets/bauhaus-icons/Batería.png")
                    break;
                default:
                    url = require("../assets/bauhaus-icons/manitas.png")
                    break
            }
            break
        case "kqJtDU5D9b": // Acentor 
            switch (item.get("Type")) {
                case "Calor":
                    url = require("../assets/acentor-icons/" + item.get("Type") + ".jpg")
                    break;
                case "Pared":
                    url = require("../assets/acentor-icons/" + item.get("Type") + ".jpg")
                    break;
                case "Escaleras":
                    url = require("../assets/acentor-icons/" + item.get("Type") + ".jpg")
                    break;
                case "Puerta de paso":
                case "Puerta de garaje":
                case "Puerta de entrada":
                    url = require("../assets/acentor-icons/Puerta de paso.jpg")
                    break;
                case "Cerámica":
                    url = require("../assets/acentor-icons/Suelo.jpg")
                    break;
                case "Pintor":
                    url = require("../assets/acentor-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/acentor-icons/fontanero.png")
                    break;
                case "Electricidad":
                    url = require("../assets/acentor-icons/electricista.jpg")
                    break;
                case "Albañil":
                    url = require("../assets/acentor-icons/albanil.png")
                    break;
                case "Electrodomesticos":
                    url = require("../assets/acentor-icons/electrodomesticos.jpg")
                    break;
                case "Reformas":
                    url = require("../assets/acentor-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/acentor-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/acentor-icons/limpieza.png")
                    break;
                case "Piscinas":
                    url = require("../assets/acentor-icons/piscina.jpg")
                    break;
                case "Toldos":
                    url = require("../assets/acentor-icons/toldos-y-persianas.jpg")
                    break;
                case "Agua caliente":
                    url = require("../assets/acentor-icons/Agua.jpg")
                    break;
                case "Carp. metal":
                    url = require("../assets/acentor-icons/carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/acentor-icons/electrodomesticos.jpg")
                    break;
                case "Climatización":
                    url = require("../assets/acentor-icons/Climatizacion.jpg")
                    break;
                case "Escaleras":
                    url = require("../assets/acentor-icons/Taladro.png")
                    break;
                case "Ventanas":
                    url = require("../assets/acentor-icons/cristalero.jpg")
                    break;
                case "Cerrajero":
                    url = require("../assets/acentor-icons/cerrajero.png")
                    break;
                case "Jardinero":
                case "Jardín":
                case "Jardin":
                    url = require("../assets/acentor-icons/jardinero.jpg")
                    break;
                case "Antenista":
                    url = require("../assets/acentor-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/acentor-icons/toldos-y-persianas.jpg")
                    break;
                case "Cristalero":
                    url = require("../assets/acentor-icons/cristalero.jpg")
                    break;
                case "Suelo":
                    url = require("../assets/acentor-icons/Suelo.jpg")
                    break;
                case "Tejado":
                    url = require("../assets/acentor-icons/Tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/acentor-icons/piscina.jpg")
                    break;
                case "Customización":
                    url = require("../assets/acentor-icons/customizacion.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/acentor-icons/Coche color.jpg")
                    break;
                case "Armarios":
                    url = require("../assets/acentor-icons/Armario.jpg")
                    break;
                case "Instalar inodoro":
                    url = require("../assets/acentor-icons/Bano.jpg")
                    break;
                case "Reparaciones":
                    url = require("../assets/acentor-icons/Coche accesorios.png")
                    break;
                case "Revisiones":
                    url = require("../assets/acentor-icons/Coche duda.png")
                    break;
                case "Accesorios":
                    url = require("../assets/acentor-icons/customizacion.png")
                    break;
                case "Paneles Solares":
                    url = require("../assets/acentor-icons/Panel solar.png")
                    break;
                case "Cocinas":
                    url = require("../assets/acentor-icons/Cocina.jpg")
                    break;
                case "Baño":
                    url = require("../assets/acentor-icons/Bano.jpg")
                    break;
                case "Baterías":
                    url = require("../assets/acentor-icons/Batería.png")
                    break;
                case "Tratamiento de Agua":
                    url = require("../assets/acentor-icons/Tratamiento.png")
                    break;
                case "Cambio de Bañera por plato":
                    url = require("../assets/acentor-icons/Ducha.jpg")
                    break;
                default:
                    url = require("../assets/acentor-icons/manitas.jpg")
                    break
            }
            break;
        case "fnijgFrRYy": // Cleverea
            switch (item.get("Type")) {
                case "Manitas":
                    url = require("../assets/cleverea-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/cleverea-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/cleverea-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/cleverea-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/cleverea-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/cleverea-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/cleverea-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/cleverea-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/cleverea-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/cleverea-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/cleverea-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/cleverea-icons/Climatizacion.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/cleverea-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/cleverea-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/cleverea-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/cleverea-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/cleverea-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/cleverea-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/cleverea-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/cleverea-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/cleverea-icons/piscina.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/cleverea-icons/chapa-y-pintura.png")
                    break;
                case "Revisiones":
                    url = require("../assets/cleverea-icons/revisiones.png")
                    break;
                case "Veh. Eléctrico":
                    url = require("../assets/cleverea-icons/veh.electrico.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/cleverea-icons/neumaticos.png")
                    break;
                case "Climatización coche":
                    url = require("../assets/cleverea-icons/climatizacion-coche.png")
                    break;
                case "Customización":
                    url = require("../assets/cleverea-icons/customizacion.png")
                    break;
                case "Accesorios":
                    url = require("../assets/cleverea-icons/accesorios.png")
                    break;
                case "Pasar ITV":
                    url = require("../assets/cleverea-icons/pasar-itv.png")
                    break;
                case "Lunas":
                    url = require("../assets/cleverea-icons/lunas.png")
                    break;
                case "Limpieza & Spa":
                    url = require("../assets/cleverea-icons/limpieza-&-spa.png")
                    break;
                case "Baja y desguace de vehículos":
                    url = require("../assets/cleverea-icons/baja-y-desguace-de-vehiculos.png")
                    break;
                case "Asist. carretera":
                    url = require("../assets/cleverea-icons/asist.carretera.png")
                    break;
                case "Baterías":
                    url = require("../assets/cleverea-icons/baterias.png")
                    break;
                case "Algo pasa, pero no sé qué...”":
                    url = require("../assets/cleverea-icons/algo-pasa-pero-no-se-que.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/cleverea-icons/reparaciones.png")
                    break;
                case "Cocinas":
                    url = require("../assets/cleverea-icons/cocina.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/cleverea-icons/lo-que-sea.png")
                    break;
                default:
                    url = require("../assets/cleverea-icons/manitas.png")
            }
            break;
        case "c8U8HpW6qb": // Master de la reforma
            switch (item.get("Type")) {
                case "Manitas":
                    url = require("../assets/master-reforma-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/master-reforma-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/master-reforma-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/master-reforma-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/master-reforma-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/master-reforma-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/master-reforma-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/master-reforma-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/master-reforma-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/master-reforma-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/master-reforma-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/master-reforma-icons/Climatizacion.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/master-reforma-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/master-reforma-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/master-reforma-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/master-reforma-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/master-reforma-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/master-reforma-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/master-reforma-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/master-reforma-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/master-reforma-icons/piscina.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/master-reforma-icons/reparaciones.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/master-reforma-icons/lo-que-sea.png")
                    break;
                default:
                    url = require("../assets/master-reforma-icons/manitas.png")
            }
            break;
        case "HJkv3xWBGh": // CUBRO
            switch (item.get("Type")) {
                case "Manitas":
                    url = require("../assets/cleverea-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/cleverea-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/cleverea-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/cleverea-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/cleverea-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/cleverea-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/cleverea-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/cleverea-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/cleverea-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/cleverea-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/cleverea-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/cleverea-icons/Climatizacion.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/cleverea-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/cleverea-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/cleverea-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/cleverea-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/cleverea-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/cleverea-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/cleverea-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/cleverea-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/cleverea-icons/piscina.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/cleverea-icons/chapa-y-pintura.png")
                    break;
                case "Revisiones":
                    url = require("../assets/cleverea-icons/revisiones.png")
                    break;
                case "Veh. Eléctrico":
                    url = require("../assets/cleverea-icons/veh.electrico.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/cleverea-icons/neumaticos.png")
                    break;
                case "Climatización coche":
                    url = require("../assets/cleverea-icons/climatizacion-coche.png")
                    break;
                case "Customización":
                    url = require("../assets/cleverea-icons/customizacion.png")
                    break;
                case "Accesorios":
                    url = require("../assets/cleverea-icons/accesorios.png")
                    break;
                case "Pasar ITV":
                    url = require("../assets/cleverea-icons/pasar-itv.png")
                    break;
                case "Lunas":
                    url = require("../assets/cleverea-icons/lunas.png")
                    break;
                case "Limpieza & Spa":
                    url = require("../assets/cleverea-icons/limpieza-&-spa.png")
                    break;
                case "Baja y desguace de vehículos":
                    url = require("../assets/cleverea-icons/baja-y-desguace-de-vehiculos.png")
                    break;
                case "Asist. carretera":
                    url = require("../assets/cleverea-icons/asist.carretera.png")
                    break;
                case "Baterías":
                    url = require("../assets/cleverea-icons/baterias.png")
                    break;
                case "Algo pasa, pero no sé qué...”":
                    url = require("../assets/cleverea-icons/algo-pasa-pero-no-se-que.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/cleverea-icons/reparaciones.png")
                    break;
                case "Cocinas":
                    url = require("../assets/cleverea-icons/cocina.png")
                    break;
                case "Armarios":
                    url = require("../assets/cleverea-icons/armarios-cubro.png")
                    break;
                case "Baños":
                    url = require("../assets/cleverea-icons/bano-cubro.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/cleverea-icons/lo-que-sea.png")
                    break;
                default:
                    url = require("../assets/cleverea-icons/manitas.png")
            }
            break;
        case "pB2BciWgw5": // Fintonic
            switch (item.get("Type")) {
                case "Paneles Solares":
                    url = require("../assets/fintonic-icons/solar-panel.png")
                    break;
                case "Manitas":
                    url = require("../assets/fintonic-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/fintonic-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/fintonic-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/fintonic-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/fintonic-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/fintonic-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/fintonic-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/fintonic-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/fintonic-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/fintonic-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/fintonic-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/fintonic-icons/climatización.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/fintonic-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/fintonic-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/fintonic-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/fintonic-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/fintonic-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/fintonic-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/fintonic-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/fintonic-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/fintonic-icons/piscina.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/fintonic-icons/chapa-y-pintura.png")
                    break;
                case "Revisiones":
                    url = require("../assets/fintonic-icons/revisiones.png")
                    break;
                case "Veh. Eléctrico":
                    url = require("../assets/fintonic-icons/veh.electrico.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/fintonic-icons/neumaticos.png")
                    break;
                case "Climatización coche":
                    url = require("../assets/fintonic-icons/climatizacion-coche.png")
                    break;
                case "Customización":
                    url = require("../assets/fintonic-icons/customizacion.png")
                    break;
                case "Accesorios":
                    url = require("../assets/fintonic-icons/accesorios.png")
                    break;
                case "Pasar ITV":
                    url = require("../assets/fintonic-icons/pasar-itv.png")
                    break;
                case "Lunas":
                    url = require("../assets/fintonic-icons/lunas.png")
                    break;
                case "Limpieza & Spa":
                    url = require("../assets/fintonic-icons/limpieza-&-spa.png")
                    break;
                case "Baja y desguace":
                    url = require("../assets/fintonic-icons/baja-y-desguace-de-vehiculos.png")
                    break;
                case "Asist. carretera":
                    url = require("../assets/fintonic-icons/asist.carretera.png")
                    break;
                case "Baterías":
                    url = require("../assets/fintonic-icons/baterias.png")
                    break;
                case "Algo pasa, pero no sé qué...”":
                    url = require("../assets/fintonic-icons/manitas.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/fintonic-icons/reparaciones.png")
                    break;
                case "Cocinas":
                    url = require("../assets/fintonic-icons/cocina.png")
                    break;
                case "Armarios":
                    url = require("../assets/fintonic-icons/armarios.png")
                    break;
                case "Baños":
                    url = require("../assets/fintonic-icons/banos.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/fintonic-icons/lo-que-sea.png")
                    break;
                default:
                    url = require("../assets/fintonic-icons/manitas.png")
            }
            break;
        case "BUFKRUAwbD": // Ciudadela
            switch (item.get("Type")) {
                case "Paneles Solares":
                    url = require("../assets/ciudadela-icons/solar-panel.png")
                    break;
                case "Manitas":
                    url = require("../assets/ciudadela-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/ciudadela-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/ciudadela-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/ciudadela-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/ciudadela-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/ciudadela-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/ciudadela-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/ciudadela-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/ciudadela-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/ciudadela-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/ciudadela-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/ciudadela-icons/Climatizacion.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/ciudadela-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/ciudadela-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/ciudadela-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/ciudadela-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/ciudadela-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/ciudadela-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/ciudadela-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/ciudadela-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/ciudadela-icons/piscina.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/ciudadela-icons/chapa-y-pintura.png")
                    break;
                case "Revisiones":
                    url = require("../assets/ciudadela-icons/revisiones.png")
                    break;
                case "Veh. Eléctrico":
                    url = require("../assets/ciudadela-icons/veh.electrico.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/ciudadela-icons/neumaticos.png")
                    break;
                case "Climatización coche":
                    url = require("../assets/ciudadela-icons/climatizacion-coche.png")
                    break;
                case "Customización":
                    url = require("../assets/ciudadela-icons/customizacion.png")
                    break;
                case "Accesorios":
                    url = require("../assets/ciudadela-icons/accesorios.png")
                    break;
                case "Pasar ITV":
                    url = require("../assets/ciudadela-icons/pasar-itv.png")
                    break;
                case "Lunas":
                    url = require("../assets/ciudadela-icons/lunas.png")
                    break;
                case "Limpieza & Spa":
                    url = require("../assets/ciudadela-icons/limpieza-&-spa.png")
                    break;
                case "Baja y desguace":
                    url = require("../assets/ciudadela-icons/baja-y-desguace-de-vehiculos.png")
                    break;
                case "Asist. carretera":
                    url = require("../assets/ciudadela-icons/asist.carretera.png")
                    break;
                case "Baterías":
                    url = require("../assets/ciudadela-icons/baterias.png")
                    break;
                case "Algo pasa, pero no sé qué...”":
                    url = require("../assets/ciudadela-icons/algo-pasa-pero-no-se-que.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/ciudadela-icons/reparaciones.png")
                    break;
                case "Cocinas":
                    url = require("../assets/ciudadela-icons/cocina.png")
                    break;
                case "Armarios":
                    url = require("../assets/ciudadela-icons/armarios-ciudadela.png")
                    break;
                case "Baños":
                    url = require("../assets/ciudadela-icons/banos.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/ciudadela-icons/lo-que-sea.png")
                    break;
                default:
                    url = require("../assets/ciudadela-icons/manitas.png")
            }
            break;
        case "lMyRIzN79G": // MUTUA
            switch (item.get("Type")) {
                case "Manitas":
                    url = require("../assets/mutua-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/mutua-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/mutua-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/mutua-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/mutua-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/mutua-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/mutua-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/mutua-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/mutua-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/mutua-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/mutua-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/mutua-icons/climatizacion.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/mutua-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/mutua-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/mutua-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/mutua-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/mutua-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/mutua-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/mutua-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/mutua-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/mutua-icons/piscina.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/mutua-icons/chapa-y-pintura.png")
                    break;
                case "Revisiones":
                    url = require("../assets/mutua-icons/revisiones.png")
                    break;
                case "Veh. Eléctrico":
                    url = require("../assets/mutua-icons/veh.electrico.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/mutua-icons/neumaticos.png")
                    break;
                case "Climatización coche":
                    url = require("../assets/mutua-icons/climatizacion-coche.png")
                    break;
                case "Customización":
                    url = require("../assets/mutua-icons/customizacion.png")
                    break;
                case "Accesorios":
                    url = require("../assets/mutua-icons/accesorios.png")
                    break;
                case "Pasar ITV":
                    url = require("../assets/mutua-icons/pasar-itv.png")
                    break;
                case "Lunas":
                    url = require("../assets/mutua-icons/lunas.png")
                    break;
                case "Limpieza & Spa":
                    url = require("../assets/mutua-icons/limpieza-&-spa.png")
                    break;
                case "Baja y desguace de vehículos":
                    url = require("../assets/mutua-icons/baja-y-desguace-de-vehiculos.png")
                    break;
                case "Asist. carretera":
                    url = require("../assets/mutua-icons/asist.carretera.png")
                    break;
                case "Baterías":
                    url = require("../assets/mutua-icons/baterias.png")
                    break;
                case "Algo pasa, pero no sé qué...”":
                    url = require("../assets/mutua-icons/algo-pasa-pero-no-se-que.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/mutua-icons/reparaciones.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/mutua-icons/lo-que-sea.png")
                    break;
                default:
                    url = require("../assets/mutua-icons/manitas.png")
            }
        break;
        case "W8fBSZcVw5": //PELAYO
            switch(item.get("Type")){
                case "Baño":
                    url = require("../assets/Pelayo-icons/bano.png")
                    break;
                case "Fontanería":
                    url = require("../assets/Pelayo-icons/fontanero.png")
                    break;
                case "Albañilería":
                    url = require("../assets/Pelayo-icons/albanil.png")
                    break;
                case "Electricidad":
                    url = require("../assets/Pelayo-icons/electricista.png")
                    break;           
                case "Carpintería":
                    url = require("../assets/Pelayo-icons/carp.madera.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/Pelayo-icons/electrodomesticos.png")
                    break;
                case "Aire Acondicionado":
                    url = require("../assets/Pelayo-icons/climatizacion.png")
                    break;
                case "Cerrajería":
                    url = require("../assets/Pelayo-icons/cerrajero.png")
                    break;
                case "Persianista":
                    url = require("../assets/Pelayo-icons/persianas.png")
                    break;
                case "Calor":
                    url = require("../assets/Pelayo-icons/calor.png")
                    break;
                default:
                    url = require("../assets/Pelayo-icons/manitas.png")
                } 
                break;
        case "2uc4nQSWfG": // GESVALT
            switch (item.get("Type")) {
                case "Paneles Solares":
                    url = require("../assets/gesvalt-icons/solar-panel.png")
                    break;
                case "Manitas":
                    url = require("../assets/gesvalt-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/gesvalt-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/gesvalt-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/gesvalt-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/gesvalt-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/gesvalt-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/gesvalt-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/gesvalt-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/gesvalt-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/gesvalt-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/gesvalt-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/gesvalt-icons/Climatizacion.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/gesvalt-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/gesvalt-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/gesvalt-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/gesvalt-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/gesvalt-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/gesvalt-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/gesvalt-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/gesvalt-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/gesvalt-icons/piscina.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/gesvalt-icons/chapa-y-pintura.png")
                    break;
                case "Revisiones":
                    url = require("../assets/gesvalt-icons/revisiones.png")
                    break;
                case "Veh. Eléctrico":
                    url = require("../assets/gesvalt-icons/veh.electrico.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/gesvalt-icons/neumaticos.png")
                    break;
                case "Climatización coche":
                    url = require("../assets/gesvalt-icons/climatizacion-coche.png")
                    break;
                case "Customización":
                    url = require("../assets/gesvalt-icons/varita.png")
                    break;
                case "Accesorios":
                    url = require("../assets/gesvalt-icons/accesorios.png")
                    break;
                case "Pasar ITV":
                    url = require("../assets/gesvalt-icons/pasar-itv.png")
                    break;
                case "Lunas":
                    url = require("../assets/gesvalt-icons/lunas.png")
                    break;
                case "Limpieza & Spa":
                    url = require("../assets/gesvalt-icons/limpieza-&-spa.png")
                    break;
                case "Baja y desguace":
                    url = require("../assets/gesvalt-icons/baja-y-desguace-de-vehiculos.png")
                    break;
                case "Asist. carretera":
                    url = require("../assets/gesvalt-icons/asist.carretera.png")
                    break;
                case "Baterías":
                    url = require("../assets/gesvalt-icons/baterias.png")
                    break;
                case "Algo pasa, pero no sé qué...”":
                    url = require("../assets/gesvalt-icons/algo-pasa-pero-no-se-que.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/gesvalt-icons/reparaciones.png")
                    break;
                case "Cocinas":
                    url = require("../assets/gesvalt-icons/cocina.png")
                    break;
                case "Armarios":
                    url = require("../assets/gesvalt-icons/armarios.png")
                    break;
                case "Baños":
                    url = require("../assets/gesvalt-icons/banos.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/gesvalt-icons/varita.png")
                    break;
                default:
                    url = require("../assets/gesvalt-icons/manitas.png")
            }
            break
        case "ZcFC5W6QCB": // DIGLO
            switch (item.get("Type")) {
                case "Paneles Solares":
                    url = require("../assets/diglo-icons/solar-panel.png")
                    break;
                case "Manitas":
                    url = require("../assets/diglo-icons/manitas.png")
                    break;
                case "Pintor":
                    url = require("../assets/diglo-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/diglo-icons/fontanero.png")
                    break;
                case "Albañil":
                    url = require("../assets/diglo-icons/albanil.png")
                    break;
                case "Electricista":
                    url = require("../assets/diglo-icons/electricista.png")
                    break;
                case "Reformas":
                    url = require("../assets/diglo-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/diglo-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/diglo-icons/limpieza.png")
                    break;
                case "Carp. madera":
                    url = require("../assets/diglo-icons/carp.madera.png")
                    break;
                case "Carp. metal":
                    url = require("../assets/diglo-icons/Carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/diglo-icons/electrodomesticos.png")
                    break;
                case "Climatización":
                    url = require("../assets/diglo-icons/Climatizacion.png")
                    break;
                case "Mudanzas":
                    url = require("../assets/diglo-icons/mudanzas.png")
                    break;
                case "Cerrajero":
                    url = require("../assets/diglo-icons/cerrajero.png")
                    break;
                case "Jardinero":
                    url = require("../assets/diglo-icons/jardinero.png")
                    break;
                case "Antenista":
                    url = require("../assets/diglo-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/diglo-icons/toldos-y-persianas.png")
                    break;
                case "Cristalero":
                    url = require("../assets/diglo-icons/cristalero.png")
                    break;
                case "Suelo":
                    url = require("../assets/diglo-icons/suelo.png")
                    break;
                case "Tejado":
                    url = require("../assets/diglo-icons/tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/diglo-icons/piscina.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/diglo-icons/chapa-y-pintura.png")
                    break;
                case "Revisiones":
                    url = require("../assets/diglo-icons/revisiones.png")
                    break;
                case "Veh. Eléctrico":
                    url = require("../assets/diglo-icons/veh.electrico.png")
                    break;
                case "Neumáticos":
                    url = require("../assets/diglo-icons/neumaticos.png")
                    break;
                case "Climatización coche":
                    url = require("../assets/diglo-icons/climatizacion-coche.png")
                    break;
                case "Customización":
                    url = require("../assets/diglo-icons/varita.png")
                    break;
                case "Accesorios":
                    url = require("../assets/diglo-icons/accesorios.png")
                    break;
                case "Pasar ITV":
                    url = require("../assets/diglo-icons/pasar-itv.png")
                    break;
                case "Lunas":
                    url = require("../assets/diglo-icons/lunas.png")
                    break;
                case "Limpieza & Spa":
                    url = require("../assets/diglo-icons/limpieza-&-spa.png")
                    break;
                case "Baja y desguace":
                    url = require("../assets/diglo-icons/baja-y-desguace-de-vehiculos.png")
                    break;
                case "Asist. carretera":
                    url = require("../assets/diglo-icons/asist.carretera.png")
                    break;
                case "Baterías":
                    url = require("../assets/diglo-icons/baterias.png")
                    break;
                case "Algo pasa, pero no sé qué...”":
                    url = require("../assets/diglo-icons/algo-pasa-pero-no-se-que.png")
                    break;
                case "Reparaciones":
                    url = require("../assets/diglo-icons/reparaciones.png")
                    break;
                case "Cocinas":
                    url = require("../assets/diglo-icons/cocina.png")
                    break;
                case "Armarios":
                    url = require("../assets/diglo-icons/armarios.png")
                    break;
                case "Baños":
                    url = require("../assets/diglo-icons/banos.png")
                    break;
                case "Lo que sea":
                    url = require("../assets/diglo-icons/varita.png")
                    break;
                default:
                    url = require("../assets/diglo-icons/manitas.png")
            }
            break;
        case "proManager": // PROMANAGER 
            switch (item.get("Type")) {
                case "Calor":
                    url = require("../assets/acentor-icons/" + item.get("Type") + ".jpg")
                    break;
                case "Pared":
                    url = require("../assets/acentor-icons/" + item.get("Type") + ".jpg")
                    break;
                case "Escaleras":
                    url = require("../assets/acentor-icons/" + item.get("Type") + ".jpg")
                    break;
                case "Puerta de paso":
                case "Puerta de garaje":
                case "Puerta de entrada":
                    url = require("../assets/acentor-icons/Puerta de paso.jpg")
                    break;
                case "Cerámica":
                    url = require("../assets/acentor-icons/Suelo.jpg")
                    break;
                case "Pintor":
                    url = require("../assets/acentor-icons/pintor.png")
                    break;
                case "Fontanero":
                    url = require("../assets/acentor-icons/fontanero.png")
                    break;
                case "Electricidad":
                    url = require("../assets/acentor-icons/electricista.jpg")
                    break;
                case "Albañil":
                    url = require("../assets/acentor-icons/albanil.png")
                    break;
                case "Electrodomesticos":
                    url = require("../assets/acentor-icons/electrodomesticos.jpg")
                    break;
                case "Reformas":
                    url = require("../assets/acentor-icons/reformas.png")
                    break;
                case "Construcción":
                    url = require("../assets/acentor-icons/construccion.png")
                    break;
                case "Limpieza":
                    url = require("../assets/acentor-icons/limpieza.png")
                    break;
                case "Piscinas":
                    url = require("../assets/acentor-icons/piscina.jpg")
                    break;
                case "Toldos":
                    url = require("../assets/acentor-icons/toldos-y-persianas.jpg")
                    break;
                case "Agua caliente":
                    url = require("../assets/acentor-icons/Agua.jpg")
                    break;
                case "Carp. metal":
                    url = require("../assets/acentor-icons/carp.metal.png")
                    break;
                case "Electrodomésticos":
                    url = require("../assets/acentor-icons/electrodomesticos.jpg")
                    break;
                case "Climatización":
                    url = require("../assets/acentor-icons/Climatizacion.jpg")
                    break;
                case "Escaleras":
                    url = require("../assets/acentor-icons/Taladro.png")
                    break;
                case "Ventanas":
                    url = require("../assets/acentor-icons/cristalero.jpg")
                    break;
                case "Cerrajero":
                    url = require("../assets/acentor-icons/cerrajero.png")
                    break;
                case "Jardinero":
                case "Jardín":
                case "Jardin":
                    url = require("../assets/acentor-icons/jardinero.jpg")
                    break;
                case "Antenista":
                    url = require("../assets/acentor-icons/antenista.png")
                    break;
                case "Toldos y Persianas":
                    url = require("../assets/acentor-icons/toldos-y-persianas.jpg")
                    break;
                case "Cristalero":
                    url = require("../assets/acentor-icons/cristalero.jpg")
                    break;
                case "Suelo":
                    url = require("../assets/acentor-icons/Suelo.jpg")
                    break;
                case "Tejado":
                    url = require("../assets/acentor-icons/Tejado.png")
                    break;
                case "Piscina":
                    url = require("../assets/acentor-icons/piscina.jpg")
                    break;
                case "Customización":
                    url = require("../assets/acentor-icons/customizacion.png")
                    break;
                case "Chapa y pintura":
                    url = require("../assets/acentor-icons/Coche color.jpg")
                    break;
                case "Armarios":
                    url = require("../assets/acentor-icons/Armario.jpg")
                    break;
                case "Instalar inodoro":
                    url = require("../assets/acentor-icons/Bano.jpg")
                    break;
                case "Reparaciones":
                    url = require("../assets/acentor-icons/Coche accesorios.png")
                    break;
                case "Revisiones":
                    url = require("../assets/acentor-icons/Coche duda.png")
                    break;
                case "Accesorios":
                    url = require("../assets/acentor-icons/customizacion.png")
                    break;
                case "Paneles Solares":
                    url = require("../assets/acentor-icons/Panel solar.png")
                    break;
                case "Cocinas":
                    url = require("../assets/acentor-icons/Cocina.jpg")
                    break;
                case "Baño":
                    url = require("../assets/acentor-icons/Bano.jpg")
                    break;
                case "Baterías":
                    url = require("../assets/acentor-icons/Batería.png")
                    break;
                case "Tratamiento de Agua":
                    url = require("../assets/acentor-icons/Tratamiento.png")
                    break;
                case "Cambio de Bañera por plato":
                    url = require("../assets/acentor-icons/Ducha.jpg")
                    break;
                default:
                    url = require("../assets/acentor-icons/manitas.jpg")
                    break
            }
            break;

        default:
            url = item.get("Icon") ? item.get("Icon").url() : null;
    }

    return url;
}
