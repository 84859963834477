import React, { Component } from "react";
import Parse from "parse";
import { connect } from 'react-redux';
import { jobCategoryGuildSubcategory } from '../store/actions/jobAction';
import { getParameterByName } from "../utils/formatter.js";
import {selectBusinessColor} from "../utils/image"

class CategoryList extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isEmpty: false,
            items: [],
            selectedOption: null,
     
        };
    }

    componentDidMount() {
        let result = this.props.service
       

        this.setState({
            isLoaded: true,
            items: result
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadAsync = newId => {
        this._isMounted = true;

        const parentPromise = new Parse.Query("Verticales").get(newId);

        const servicePromise = parentPromise.then(parent => {
            return new Parse.Query("Verticales")
                .equalTo("Father", parent)
                .equalTo("Active", true)
                .ascending("Position")
                .find();
        });
        let codeBussines =  getParameterByName("business")

        Promise.all([parentPromise, servicePromise]).then(
            ([parent, result]) => {
                if (result.length > 0) {
                    if (this._isMounted) {
                       
                        this.setState({
                            isLoaded: true,
                            items: result
                        });
                        this.props.parentTitle(parent.get("ViewTitle"));
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({
                            isLoaded: true,
                            isEmpty: true
                        });

                        this.props.mixpanel.track("Paso 2 completado");

                        this.props.update({
                            sub: parent.id
                        });
                        const location = parent.get("Location");
                        let data = {
                            subCategoryId: parent.id,
                            latitude: location ? location.latitude : null,
                            longitude: location ? location.longitude : null
                        }
                        this.props.jobCategoryGuildSubcategory(data);
                        this.props.empty(true);

                        this.props.history.push({
                            pathname: "/description",
                            search: this.props.location.search,
                            state: {
                                inner: true,
                                param: true
                            }
                        });
                    }
                }
            },
            error => {
                if (this._isMounted) {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            }
        );
    };

    handleChange = e => {
        this.setState({
            selectedOption: e.target.value
        });

        this.loadAsync(e.target.value);
    };

    render() {
        const { error, isLoaded, items, selectedOption } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Cargando...</div>;
        } else {
            return items.map((service, index) => {
                return (
                    <li key={service.id}>
                        <input
                            type="radio"
                            id={`subvert-item-${index}`}
                            name="subvert-item"
                            value={service.id}
                            className="subvert-item native-hidden"
                            checked={selectedOption === service.id}
                            onChange={this.handleChange}
                        />
                        <label htmlFor={`subvert-item-${index}`}>
                            <svg width="20px" height="20px" viewBox="0 0 20 20">
                                <defs>
                                    {selectedOption === service.id ? (
                                        <path
                                            d="M10,20 C4.4775,20 0,15.5225 0,10 C0,4.4775 4.4775,0 10,0 C15.5225,0 20,4.4775 20,10 C20,15.5225 15.5225,20 10,20 Z M10,18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,5.39762708 14.6023729,1.66666667 10,1.66666667 C5.39762708,1.66666667 1.66666667,5.39762708 1.66666667,10 C1.66666667,14.6023729 5.39762708,18.3333333 10,18.3333333 Z M14.1666667,10 C14.1666667,12.3010417 12.3010417,14.1666667 10,14.1666667 C7.69895833,14.1666667 5.83333333,12.3010417 5.83333333,10 C5.83333333,7.69895833 7.69895833,5.83333333 10,5.83333333 C12.3010417,5.83333333 14.1666667,7.69895833 14.1666667,10 Z"
                                            id={`path-${index}`}
                                        />
                                    ) : (
                                        <path
                                            d="M10,20 C4.4775,20 0,15.5225 0,10 C0,4.4775 4.4775,0 10,0 C15.5225,0 20,4.4775 20,10 C20,15.5225 15.5225,20 10,20 Z M10,18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,5.39762708 14.6023729,1.66666667 10,1.66666667 C5.39762708,1.66666667 1.66666667,5.39762708 1.66666667,10 C1.66666667,14.6023729 5.39762708,18.3333333 10,18.3333333 Z"
                                            id={`path-${index}`}
                                        />
                                    )}
                                </defs>
                                <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                >
                                    <g transform="translate(0.000000, -14.000000)">
                                        <g transform="translate(0.000000, 14.000000)">
                                            <mask
                                                id={`mask-${index}`}
                                                fill="white"
                                            >
                                                <use
                                                    xlinkHref={`#path-${index}`}
                                                />
                                            </mask>
                                            <g />
                                            <g
                                                mask={`url(#mask-${index})`}
                                                fillRule="evenodd"
                                                fill={
                                                    selectedOption ===
                                                    service.id
                                                        ? selectBusinessColor(getParameterByName("business"))
                                                        : "#d2d2d2"
                                                }
                                            >
                                                <rect
                                                    x="0"
                                                    y="0"
                                                    width="20"
                                                    height="20"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p>{service.get("Type")}</p>
                        </label>
                    </li>
                );
            });
        }
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        jobCategoryGuildSubcategory: data => dispatch(jobCategoryGuildSubcategory(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);