import React, { Component } from "react";
import Parse from "parse";

import Nav from "../components/Nav";
import CategoryList from "../components/CategoryList";
import StepControlls from "../components/StepControlls";
import { connect } from 'react-redux';
import { saveCodeJustNow } from '../store/actions/justNowActions';
import "../styles/service-category.css";

class ServiceCategory extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isEmpty: false,
            items: [],
        };
    }

    componentDidMount() {
        this.isCodeJustNowExist();
        this.isBusiness();
        this._isMounted = true;

        const parentPromise = new Parse.Query("Verticales")
            .include("Father")
            .get(this.props.match.params.subCategoryId);

        const servicePromise = parentPromise.then(parent => {
            return new Parse.Query("Verticales")
                .equalTo("Father", parent)
                .equalTo("Active", true)
                .ascending("Position")
                .find();
        });

        Promise.all([parentPromise, servicePromise]).then(
            ([parent, result]) => {
                this.props.updateMiddleCategory(
                    this.props.match.params.subCategoryId
                );
                this.props.update({
                    id: parent.get("Father")
                        ? parent.get("Father").id
                        : parent.id,
                    type: parent.get("Father")
                        ? parent.get("Father").get("Type")
                        : parent.get("Type"),
                    sub: parent.get("Father") ? parent.id : ""
                });

                if (result.length > 0) {
                    if (this._isMounted) {
                        this.setState({
                            isLoaded: true,
                            title: parent.get("ViewTitle"),
                            items: result
                        });
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({
                            isLoaded: true,
                            isEmpty: true
                        });
                    }
                    this.props.history.replace({
                        pathname: "/description",
                        search: this.props.location.search,
                        state: {
                            inner: true
                        }
                    });
                }
            },
            error => {
                if (this._isMounted) {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            }
        );
    }
    isCodeJustNowExist = () => {
        let pathname = this.props.location.pathname.split('/');
        let justNow = pathname[pathname.length - 1].split('=');
        if(justNow[0] === 'justnow'){
            localStorage.setItem('codJustNow', justNow[1]);
            this.props.saveCodeJustNow(justNow[1]);
        }
    }

    isBusiness = () => {
        let parameters = window.location.pathname;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    childTitleHandler = dataFromChild => {
        this.setState({
            title: dataFromChild
        });
    };

    childHandler = dataFromChild => {
        this.setState({
            isEmpty: dataFromChild
        });
    };

    update = dataFromChild => {
        this.props.update(dataFromChild);
    };

    render() {
        const { error, isLoaded, title, items } = this.state;

        if (error) return <div>Error: {error.message}</div>;
        else {
            return (
                <div id="service-category" className="section">
                    <Nav />
                    <div className="margin-top-72px">
                        <div className="container">
                            <div className="row">
                                {isLoaded && (
                                    <div className="col-md-6">
                                        <h4 className="title">{title}</h4>
                                        <p>
                                            Selecciona una de las opciones para
                                            aportar más información al
                                            profesional.
                                        </p>
                                        <div className="content">
                                            <ul className="subvert-list">
                                                <CategoryList
                                                    service={items}
                                                    parent={
                                                        this.props.match.params
                                                            .serviceId
                                                    }
                                                    parentTitle={
                                                        this.childTitleHandler
                                                    }
                                                    empty={this.childHandler}
                                                    update={this.update}
                                                    mixpanel={
                                                        this.props.mixpanel
                                                    }
                                                    history={this.props.history}
                                                    location={
                                                        this.props.location
                                                    }
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <StepControlls
                        history={this.props.history}
                        location={this.props.location}
                        page={0}
                        nextHidden={true}
                        prev="root"
                    />
                    <img
                        src="https://pixel.mathtag.com/event/img?mt_id=1443635&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                        width="1"
                        height="1"
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        isPelayo: state.businessCodeReducer.isPelayo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveCodeJustNow: (code) => dispatch(saveCodeJustNow(code))
    }
}

export default connect(null, mapDispatchToProps) (ServiceCategory);
