import React, { Component } from "react";
import { Link } from "react-router-dom";
import {selectBusinessIcon, selectBusinessTerms} from "../utils/image";
import { getParameterByName } from "../utils/formatter";

import "../styles/service-item.css";

export default class CustomServiceItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            item: {}
        };
    }

    componentDidMount() {
        this.setState({
            isLoaded: true,
            item: this.props.service
        });
    }

    clickHandler = e => {
        this.props.mixpanel.track("Paso 1 completado");

        this.props.update({
            id: e.target.getAttribute("dataid"),
            type: e.target.getAttribute("datatype")
        });
    }

    render() {
        const { error, isLoaded, item } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Cargando...</div>;
        } else {
            return (
                <div className="service-item custom">
                    <div className="title-container">
                        <h3>¿No encuentras lo que necesitas?</h3>
                        <p>
                            Selecciona lo que sea, intentaremos cubrir tu
                            necesidad.
                        </p>
                    </div>
                    <div className="img-container">
                        <img
                            src={selectBusinessIcon(item, getParameterByName("business"))}
                            alt={item.get("Type")}
                        />
                    </div>
                    <div className="excerpt">
                        <h4 className="title">
                            <Link
                                to={{
                                    pathname: "/description",
                                    search: this.props.location.search,
                                    state: {
                                        inner: true
                                    }
                                }}
                                dataid={item.id}
                                datatype={item.get("Type")}
                                onClick={this.clickHandler}
                            >
                                {item.get("Type")}
                            </Link>
                        </h4>
                    </div>
                    {selectBusinessTerms(getParameterByName("business"), "home")}
                </div>
            );
        }
    }
}
