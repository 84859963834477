const stateInitial = {
    isPelayo: false,
    isMutua: false
}

function reducer(state = stateInitial, action){
    switch(action.type){
        case 'IS_PELAYO':
            return {
                ...state,
                isPelayo: false
            }
        case 'IS_MUTUA':
            return {
                ...state,
                isMutua: action.payload
            }
        default:
            return state;
    }
}

export default reducer;