import React, { Component } from "react";

import TermsModal from "../components/TermsModal";

import "../styles/sign-in.css";

export default class TermsContainer extends Component {

    hideModal = childData => {
        this.props.hideTermsModal(childData);
    };

    componentDidMount() {
        document.querySelector("html").classList.add("noscroll");
        document.body.classList.add("noscroll");
    }

    componentWillUnmount() {
        document.querySelector("html").classList.remove("noscroll");
        document.body.classList.remove("noscroll");
    }

    render() {
        return (
            <div className="overlay">
                <TermsModal
                    title="Hemos cambiado nuestros términos y condiciones"
                    excerpt={
                        <React.Fragment>
                            Queremos seguir construyendo contigo una comunidad
                            basada en la confianza y el respeto. Para
                            conseguirlo necesitamos que aceptes los nuevos{" "}
                            <a
                                href="https://jobin.es/terminos-condiciones/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Términos y Condiciones
                            </a>
                            , así como nuestra{" "}
                            <a
                                href="https://jobin.es/politica-privacidad/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Política de privacidad
                            </a>
                            .
                        </React.Fragment>
                    }
                    button="Aceptar"
                    type="terms"
                    history={this.props.history}
                    location={this.props.location}
                    hideModal={this.hideModal}
                    update={this.update}
                    updateTerms={true}
                />
            </div>
        );
    }
}
