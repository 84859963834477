import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getFromAPI } from "../api/request.js";

import "../styles/search-service.css";

export default function SearchService(props) {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [resultsVisible, setResultsVisible] = useState(false);
    const [timer, setTimer] = useState(0);

    const searchQuery = query => {
        if (query !== "") {
            getFromAPI("services", `s=${query}`).then(
                result => {
                    setResults(result);
                    setResultsVisible(true);
                },
                () => {
                    setResults([]);
                    setResultsVisible(false);
                }
            );
        } else {
            setResults([]);
            setResultsVisible(false);
        }
    };

    const changeHandler = e => {
        const value = e.target.value;
        setQuery(value);

        // check if query contains a word to search, a word is meassured if the query contains spaces
        if (value.split(" ").length > 1) searchQuery(value);

        // search every 300ms
        if (timer) clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                searchQuery(value);
            }, 300)
        );
    };

    const focusHandler = () => {
        if (results.length > 1) setResultsVisible(true);
    };

    const clickHandler = e => {
        props.mixpanel.track("Paso 1 completado");

        props.update({
            id: e.target.getAttribute("dataid"),
            type: e.target.getAttribute("datatype")
        });
    };

    const bodyClickHandler = e => {
        if (!e.target.classList.contains("no-hide")) setResultsVisible(false);
    };

    useEffect(() => {
        document.body.addEventListener("click", bodyClickHandler, false);
        // unmount
        return () => {
            document.body.removeEventListener("click", bodyClickHandler, false);
        };
    }, []);

    return (
        <div className="search-service">
            {/* <div className="input-search-container">
                <input
                    className={
                        resultsVisible
                            ? "jobin-input-search no-hide showing-results"
                            : "jobin-input-search no-hide"
                    }
                    type="search"
                    placeholder="Busca el servicio"
                    value={query}
                    onChange={changeHandler}
                    onFocus={focusHandler}
                />
                <button>
                    <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        className="search-icon"
                    >
                        <title>buscar</title>
                        <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            className="color-fill"
                        >
                            <path
                                d="M22.707,21.293 C23.098,21.684 23.098,22.316 22.707,22.707 C22.316,23.098 21.684,23.098 21.293,22.707 L15.614,17.028 C14.074,18.259 12.125,19 10,19 C5.029,19 1,14.971 1,10 C1,5.029 5.029,1 10,1 C14.971,1 19,5.029 19,10 C19,12.125 18.259,14.074 17.028,15.614 L22.707,21.293 Z M3,10 C3,13.866 6.134,17 10,17 C13.866,17 17,13.866 17,10 C17,6.134 13.866,3 10,3 C6.134,3 3,6.134 3,10 Z"
                                fill="#B0B0B0"
                            />
                        </g>
                    </svg>
                </button>
            </div> */}
            {resultsVisible && (
                <ul className="query-results">
                    {results.map((result, index) => {
                        return (
                            <li key={result.id + index} className="no-hide">
                                <Link
                                    to={{
                                        pathname: `/category/${result.id}`,
                                        search: props.location.search,
                                        state: {
                                            inner: true
                                        }
                                    }}
                                    dataid={
                                        result.fatherId
                                            ? result.fatherId
                                            : result.id
                                    }
                                    datatype={
                                        result.fatherName
                                            ? result.fatherName
                                            : result.name
                                    }
                                    onClick={clickHandler}
                                    className="no-hide"
                                >
                                    {result.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
