const stateInitial = {
    justNow: null
}

function reducer(state = stateInitial, action){
    switch(action.type){
        case 'SAVE_CODE_JUSTNOW':
            return {
                ...state,
                justNow: action.payload
            }
        
        case 'SET_NULL_CODE_JUSTNOW':
            return {
                ...state,
                justNow: null
            }

        default: 
            return state;
    }
}

export default reducer;