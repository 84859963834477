 const initialState = {
        history: null,
        location: null,
        match: null,
        guild: null,
        store: {
            latitude: null,
            longitude: null,
            exists: false
        },
        transport: false,
        currentUser: "",
        fromLeadToFixed: false,
        leadToFixedId: "",
        breadCrumb: [
            {
                name: "Tipo de servicio",
                pathname: "/job"
            }
        ],
        category: {
            step: 1,
            categoryId: "",
            categoryType: "",
            subCategoryId: ""
        },
        locate: {
            step: 4,
            mapsKey: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus",
            map: null,
            maps: null,
            area: null,
            center: {
                lat: 40.416782,
                lng: -3.703391
            },
            zoom: 12,
            address: "",
            address2: "",
            addressSelected: null,
            error: null,
            disabledBtn: true,
            postalCode: null
        },
        directionsService: null,
        directionsDisplay: null,
        weight: {
            amount: "",
            error: null,
            disabledBtn: true
        },
        price: {
            amount: null,
            error: null,
            disabledBtn: false,
            extraAmount: null,
            extrasArray: [],
            selectedOption: "fixed",
            buttonTitle: "Aceptar y continuar"
        },
        availability: {
            dateTo: null,
            error: null,
            disabledBtn: false,
            buttonTitle: "Continuar sin fecha"
        },
        date: {
            dateTransport: null,
            error: null,
            disabledBtn: false,
            buttonTitle: "Continuar sin fecha"
        },
        description: {
            step: 2,
            message: "",
            minChar: 40,
            maxChar: 1000,
            tooltip: "",
            styleType: "primary",
            error: null,
            disabledBtn: true
        },
        picture: {
            step: 3,
            files: [],
            textBtn: "Continuar sin fotos"
        },
        urgency: {
            step: 4,
            priority: null,
            selectedOption: null,
            error: null,
            disabledBtn: true
        },
        signIn: {
            visible: false,
            step: 1,
            phoneModal: {
                areaCode: "+34",
                phone: "",
                contactMode: "chat",
                disabledBtn: false
            },
            nameModal: {
                name: "",
                email: "",
                disabledBtn: false
            }
        },
        billingInfo: {
            id: "",
            fiscalName: "",
            identityNumber: "",
            fiscalAddress: "",
            fiscalPostalCode: "",
            fiscalCity: "",
            visible: false
        },
        results: {
            leadId: null,
            transportId: null,
            fixedId: null,
            fixedDraftId: null,
            userPDF: null,
            jobinPDF: null,
            joberPDF: null,
            warehousePDF: null
    }
 }

 function reducer(state = initialState, action){
    switch(action.type){
        // job
        case "JOB_GUILD":
           return {
               ...state,
               job: {
                   ...state.job,
                   guild: action.guild
               }
           };
       case "JOB_STORE":
           return {
               ...state,
               job: {
                   ...state.job,
                   store: {
                       ...state.job.store,
                       exists: action.exists
                   }
               }
           };
       case "JOB_ROUTER":
            return {
               ...state,
                history: action.payload.history,
                location: action.payload.location,
                match: action.payload.match
           };
       case "JOB_UPDATE_BREADCRUMB":
           return {
               ...state,
               job: {
                   ...state.job,
                   breadCrumb: action.breadCrumb
               }
           };
       case "JOB_CATEGORY_GUILD_ID_TYPE":
           return {
               ...state,
               job: {
                   ...state.job,
                   category: {
                       ...state.job.category,
                       categoryId: action.categoryId,
                       categoryType: action.categoryType
                   }
               }
           };
       case "JOB_CATEGORY_GUILD_SUBCATEGORY":
           return {
               ...state,
                category: {
                    ...state.category,
                    subCategoryId: action.payload.subCategoryId
                },
                store: {
                    ...state.store,
                    latitude: action.payload.latitude,
                    longitude: action.payload.longitude,
                    exists: action.exists
                }
           };
       case "JOB_LOCATE_GMAPS":
           return {
               ...state,
               job: {
                   ...state.job,
                   locate: {
                       ...state.job.locate,
                       map: action.map ? action.map : state.job.locate.map,
                       maps: action.maps ? action.maps : state.job.locate.maps,
                       area: action.area ? action.area : state.job.locate.area
                   },
                   directionsService: action.directionsService
                       ? action.directionsService
                       : state.job.directionsService,
                   directionsDisplay: action.directionsDisplay
                       ? action.directionsDisplay
                       : state.job.directionsDisplay
               }
           };
       case "JOB_LOCATE":
           return {
               ...state,
               job: {
                   ...state.job,
                   locate: {
                       ...state.job.locate,
                       address: action.address,
                       address2: action.address2,
                       addressSelected: action.addressSelected,
                       center: action.center,
                       disabledBtn: action.disabledBtn,
                       error: action.error,
                       postalCode: action.postalCode
                   }
               }
           };
       case "JOB_TRANSPORT":
           return {
               ...state,
               job: {
                   ...state.job,
                   transport: action.transport
               }
           };
       case "JOB_WEIGHT":
           return {
               ...state,
               job: {
                   ...state.job,
                   weight: {
                       ...state.job.weight,
                       amount: action.amount,
                       disabledBtn: action.disabledBtn,
                       error: action.error
                   }
               }
           };
       case "JOB_PRICE":
           return {
               ...state,
                price: {
                    ...state.price,
                    amount: action.payload.amount
                }
           };
       case "JOB_PRICE_EXTRAS":
           return {
               ...state,
                price: {
                    ...state.price,
                    extrasArray: action.payload.extrasArray,
                    extraAmount: action.payload.extraAmount
                }
           };
       case "JOB_PRICE_SELECTED":
           return {
               ...state,
                   price: {
                       ...state.price,
                       selectedOption: action.selectedOption,
                       buttonTitle: action.buttonTitle
                   },
                   urgency: {
                       ...state.urgency,
                       selectedOption: action.selectedOption
                   }
           };
       case "JOB_AVAILABILITY":
           return {
               ...state,
                availability: {
                    ...state.availability,
                    dateTo: action.payload.dateTo,
                    buttonTitle: action.payload.buttonTitle
                }
           };
       case "JOB_DATE":
           return {
               ...state,
               job: {
                   ...state.job,
                   date: {
                       ...state.job.date,
                       dateTransport: action.dateTransport,
                       buttonTitle: action.buttonTitle
                   }
               }
           };
       case "JOB_DESCRIPTION":
           return {
               ...state,
                description: {
                    ...state.description,
                    message: action.payload.message,
                    tooltip: action.payload.tooltip,
                    styleType: action.payload.styleType,
                    error: action.payload.error,
                    disabledBtn: action.payload.error
                }
           };
       case "JOB_PICTURE":
           return {
               ...state,
               job: {
                   ...state.job,
                   picture: {
                       ...state.job.picture,
                       files: action.files
                   }
               }
           };
       case "JOB_URGENCY":
           return {
               ...state,
               job: {
                   ...state.job,
                   urgency: {
                       ...state.job.urgency,
                       selectedOption: action.selectedOption
                   }
               }
           };
       case "JOB_SIGNIN_STEP":
           return {
               ...state,
               job: {
                   ...state.job,
                   signIn: {
                       ...state.job.signIn,
                       step: action.step
                   }
               }
           };
       case "JOB_SIGNIN_SHOW_HIDE":
           return {
               ...state,
               job: {
                   ...state.job,
                   signIn: {
                       ...state.job.signIn,
                       visible: action.visible
                   }
               }
           };
       case "JOB_SIGNIN_PHONE":
           return {
               ...state,
               job: {
                   ...state.job,
                   signIn: {
                       ...state.job.signIn,
                       phoneModal: {
                           ...state.job.signIn.phoneModal,
                           areaCode: action.areaCode,
                           phone: action.phone
                       }
                   }
               }
           };
       case "JOB_SIGNIN_NAME":
           return {
               ...state,
               job: {
                   ...state.job,
                   signIn: {
                       ...state.job.signIn,
                       nameModal: {
                           ...state.job.signIn.nameModal,
                           name: action.name,
                           email: action.email
                       }
                   },
                   currentUser: action.currentUser
               }
           };
       case "JOB_LEAD_TO_FIXED":
           return {
               ...state,
               job: {
                   ...state.job,
                   fromLeadToFixed: action.fromLeadToFixed,
                   leadToFixedId: action.leadToFixedId,
                   signIn: {
                       ...state.job.signIn,
                       nameModal: {
                           ...state.job.signIn.nameModal,
                           name: action.name,
                           email: action.email
                       },
                       phoneModal: {
                           ...state.job.signIn.phoneModal,
                           areaCode: "",
                           phone: action.phone
                       }
                   }
               }
           };
       case "JOB_RESULTS_FIXED_DRAFT":
           return {
               ...state,
               job: {
                   ...state.job,
                   results: {
                       ...state.job.results,
                       fixedDraftId: action.fixedDraftId,
                       userPDF: action.userPDF,
                       jobinPDF: action.jobinPDF,
                       joberPDF: action.joberPDF
                   }
               }
           };
       case "JOB_RESULTS_FIXED":
           return {
               ...state,
               job: {
                   ...state.job,
                   results: {
                       ...state.job.results,
                       fixedId: action.fixedId,
                       userPDF: action.userPDF,
                       jobinPDF: action.jobinPDF,
                       joberPDF: action.joberPDF
                   }
               }
           };
       case "JOB_RESULTS_LEAD":
           return {
               ...state,
               job: {
                   ...state.job,
                   results: {
                       ...state.job.results,
                       leadId: action.leadId
                   }
               }
           };
       case "JOB_RESULTS_TRANSPORT":
           return {
               ...state,
               job: {
                   ...state.job,
                   results: {
                       ...state.job.results,
                       transportId: action.transportId,
                       userPDF: action.userPDF,
                       jobinPDF: action.jobinPDF,
                       joberPDF: action.joberPDF,
                       warehousePDF: action.warehousePDF
                   }
               }
           };
       case "JOB_RESET":
           const history = state.job.history;
           const location = state.job.location;
           const match = state.job.match;
           return {
               ...state,
               job: {
                   ...action.job,
                   history: history,
                   location: location,
                   match: match
               }
           };
        default: 
           return state;
    }
 }

 export default reducer;