import React, { useState, useEffect } from "react";
import Parse from "parse";
import { getParameterByName } from "../utils/formatter";

import "../styles/service-steps.css";

import number1 from "../assets/step-item-1.svg";
import number2 from "../assets/step-item-2.svg";
import number3 from "../assets/step-item-3.svg";
import {selectBusinessTerms} from "../utils/image";

export default function ServiceSteps() {
    const [isBusiness, setIsBusiness] = useState(false);

    useEffect(() => {
        const checkBusiness = async () => {
            const businessId = getParameterByName("business");
            try {
                const business = await new Parse.Query("Business").get(
                    businessId
                );
                if (business) setIsBusiness(business);
            } catch (e) {}
        };
        checkBusiness();
    }, []);

    const checkCoppy = (businessId) => {
        switch (businessId) {
            case "mCdXO7Rjms":
                return(
                    <>
                        <div className="step-item">
                            <p className="title">Servicio realizado por Wolly.</p>
                            <p>Wolly es la plataforma digital integral líder en la búsqueda de los mejores profesionales y en la asistencia de los servicios del hogar</p>
                        </div>
                        <div className="step-item">
                            <p className="title">Selecciona el servicio.</p>
                            <p>Indícanos el tipo de servicio o instalación que necesitas y realiza el pago para que Wolly te envíe uno de sus profesionales cualificados para realizar el servicio.</p>
                        </div>
                       
                    </>
                )
                break;
            case "iopoWDLaeq":
                return(
                    <>
                        <div className="step-item">
                            <p className="title">Servicio realizado por Wolly.</p>
                            <p>Wolly es la plataforma digital integral líder en la búsqueda de los mejores profesionales y en la asistencia de los servicios del hogar</p>
                        </div>
                        <div className="step-item">
                            <p className="title">Selecciona el servicio.</p>
                            <p>Indícanos el tipo de servicio o instalación que necesitas y realiza el pago para que Wolly te envíe uno de sus profesionales cualificados para realizar el servicio.</p>
                        </div>
                       
                    </>
                )
                break;
        
            default:
                return(
                    <>
                        <div className="step-item">
                            <img src={number1} alt="1" />
                            <p className="title">Busca el servicio que necesitas.</p>
                            <p>
                                Describe el servicio que estás buscando.
                            </p>
                        </div>
                        <div className="step-item">
                            <img src={number2} alt="2" />
                            <p className="title">Conoce las condiciones por adelantado.</p>
                            <p>
                                Si es posible, te mostramos el presupuesto al instante.
                            </p>
                        </div>
                        <div className="step-item">
                            <img src={number3} alt="3" />
                            <p className="title">Te mandamos a un profesional.</p>
                            <p>
                                Si aceptas el servicio, te ponemos en contacto con un profesional recomendado por los clientes.
                            </p>
                        </div>
                    </>
                )
                break;
        }              
        
    }

    return (
        <div className="row">
            <div id="service-steps" className="hidden-md hidden-sm hidden-xs">
                {checkCoppy(getParameterByName("business"))}
            </div>
            {/*{selectBusinessTerms(getParameterByName("business"))}*/}
        </div>
    )
}
