export const isPelayo = value => {
    return {
        type: 'IS_PELAYO',
        payload: value
    }
}

export const isMutua = value => {
    return {
        type: 'IS_MUTUA',
        payload: value
    }
}