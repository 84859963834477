export const saveCodeJustNow = code => {
    return {
        type: 'SAVE_CODE_JUSTNOW',
        payload: code
    }
}

export const setNullCodeJustNow = () => {
    return {
        type: 'SET_NULL_CODE_JUSTNOW',
        payload: null
    }
}