import React, { useState } from "react";
import Parse from "parse";
import Jobin from "jobin-client";
import { PulseLoader } from "react-spinners";
import { optimizeImage, uploadImage } from "../utils/image.js";
import { getParameterByName } from "../utils/formatter.js";
import { connect } from 'react-redux';
import { setNullCodeJustNow } from '../store/actions/justNowActions';
import { selectBusinessColor } from "../utils/image";
import { saveName, saveEmail, savePhone } from '../store/actions/userAction';
import { apiId, apiKey, jsKey, urlServer } from '../components/constants/credentials';


import "../styles/step-controlls.css";

function TermsModal(props) {
    const [loading, setLoading] = useState(false);
    const [termsDisabledBtn, setTermsDisabledBtn] = useState(false);
    const [failedUpdate, setFailedUpdate] = useState(false);

    const next = () => {
        props.updateStep(props.step + 1);
    };

    const submit = () => {
        setTermsDisabledBtn(true);
        setLoading(true);
        
        // update the terms
        const updateTermsPromise = Jobin.Client.updateTerms();
        updateTermsPromise.catch(() => {
            setTermsDisabledBtn(false);
            setLoading(false);
            setFailedUpdate(true);
        });
        const businessId = getParameterByName("business");
        const businessPromise = businessId && businessId != "null"  ? new Parse.Query("Business").get(businessId) : Promise.resolve(undefined);

        if(props.jobTypeState === "fixed" && props.price > 0 && props.jobInfo.hasStripeModal){
            props.updateStep(8);
        }else{
            const createJobPromise = Promise.all([
                Parse.User.currentAsync(),
                updateTermsPromise,
                businessPromise
            ]).then(async ([ user, terms, business]) => {

                
                if (terms.result == true  && (props.checkAllowedBusiness(businessId))) {

                    user.set("Name", props.name);
                    user.set("email", props.email);
                    
                    await user.save();
                    props.mixpanel.alias(user.id);
                    props.mixpanel.identify(props.mixpanel.get_distinct_id());
                    props.mixpanel.people.set({
                        $name: user.get("Name"),
                        $phone: user.get("Phone"),
                        $email: user.get("email"),
                        "Nº de trabajos": user.get("Tasks"),
                        Nivel: user.get("Level")
                    });
                    props.mixpanel.track("Registro nombre completado");
                    
                    
                    if(props.jobTypeState === 'fixed') {
                        const fixed = await Jobin.Job.createFixedJob(
                            user.id,
                            props.jobInfo.category.categoryId,
                            props.jobInfo.category.categoryType,
                            props.jobInfo.category.subCategoryId !== "" ? props.jobInfo.category.subCategoryId : undefined,
                            props.jobInfo.locate.center.lat,
                            props.jobInfo.locate.center.lng,
                            null,
                            props.jobInfo.description.message,
                            props.jobInfo.picture.files,
                            'fixed',
                            null,
                            'web',
                            true,
                            3,
                            props.jobAddress,
                            null,
                            "draft",
                            business ? business.id : undefined,
                            null,
                            null,
                            null,
                            props.jobInfo.multiplier
                        );
                        props.jobType(null);
                        props.jobAvailability({ dateTo: null });
                        props.jobDescription({ message: null})
                        if(business.id != null){
                            Parse.initialize(apiId, jsKey);
                            Parse.serverURL = urlServer;
                            Parse.User.enableUnsafeCurrentUser();
            
                            let data = {
                                businessId: business.id,
                                id:user.id,
                            }
            
                             await Parse.Cloud.run("updateUserBusiness", data);
                        }
                        return fixed;
                    } else {
                        if(business.id != null){
                            Parse.initialize(apiId, jsKey);
                            Parse.serverURL = urlServer;
                            Parse.User.enableUnsafeCurrentUser();
            
                            let data = {
                                businessId: business.id,
                                id:user.id,
                            }
            
                             await Parse.Cloud.run("updateUserBusiness", data);
                        }
                        return Parse.Cloud.run("createJob", {
                            userId: user.id,
                            verticalId: props.jobInfo.category.categoryId,
                            verticalType: props.jobInfo.category.categoryType,
                            subCategoryId:
                                props.jobInfo.category.subCategoryId !== ""
                                    ? props.jobInfo.category.subCategoryId
                                    : undefined,
                            description: props.jobInfo.description.message,
                            images: props.jobInfo.picture.files.length > 0 ? props.jobInfo.picture.files : undefined,
                            latitud: props.jobInfo.locate.center.lat,
                            longitud: props.jobInfo.locate.center.lng,
                            priority: props.jobInfo.urgency.selectedOption,
                            fromIntegration: "web",
                            businessId: business ? business.id : undefined,
                            state: business ? "abierto" : undefined,
                            jobTypeState: props.jobTypeState
                        });
                    }
                } else if(!props.checkAllowedBusiness(businessId))props.updateStep(5);
            })
            .catch((error)=>{
                console.error(error)
                props.updateStep(5);
            });
           

            createJobPromise.then(() => {
                setTermsDisabledBtn(false);
                setLoading(false);

                props.hideModal(false);
                props.mixpanel.track("Registro completado");
                props.mixpanel.track("Trabajo guardado");
                const clickId = getParameterByName("eid");
                if (clickId) {
                    var xhr = new XMLHttpRequest();
                    xhr.open(
                        "GET",
                        `https://eat.emmasolutions.net/postback?eid=${clickId}`,
                        false
                    );
                    xhr.send(null);
                    if (xhr.status === 200) console.log("emma postback ok");
                }
                props.history.push({
                    pathname: "summary",
                    search: props.location.search,
                    state: {
                        inner: true
                    }
                });
                sendDataToJustNow();
                
            }).catch(err => {
                console.error(err)
                setTermsDisabledBtn(false);
                setLoading(false);
                // checks if the error is caused by no jobers or if its just an internal error
                if (
                    err
                        .toString()
                        .split(" ")
                        .includes("NO_JOBERS")
                )
                    props.updateStep(6);
                else next();
            });
            
        }
    };

    const sendDataToJustNow = () => {
        props.saveName(null);
        props.saveEmail(null);
        props.savePhone(null);
        localStorage.removeItem('Parse/ExxNZxyGaH3MmDYWIwKmg6okGr3VWySlxp4Dk16P/currentUser');
    }

    const updateTerms = () => {
        setTermsDisabledBtn(true);

        setLoading(true);

        // update the terms
        const updateTermsPromise = Jobin.Client.updateTerms();

        updateTermsPromise.catch(() => {
            setTermsDisabledBtn(false);
            setLoading(false);
            setFailedUpdate(true);
        });

        props.hideModal(false);
    };

    return (
        <div className="modal terms">
            <h4 className="title terms">{props.title}</h4>
            {failedUpdate ? (
                <p className="excerpt">
                    Actualizar los términos y condiciones ha fallado, inténtelo
                    de nuevo, si tiene problemas o dudas comunicarse a 648097254.
                </p>
            ) : (
                <p className="excerpt">{props.excerpt}</p>
            )}
            <div className="one-row-button retry">
                <button
                    style={{ backgroundColor:selectBusinessColor(getParameterByName("business")) , color:  (getParameterByName("business") == "lMyRIzN79G" || getParameterByName("business") == "ZF5WZAbvQ6" || getParameterByName("business") == "kqJtDU5D9b") ? "white": "black"}}

                    type="button"
                    onClick={props.updateTerms ? updateTerms : submit}
                    disabled={termsDisabledBtn}
                >
                    {loading ? (
                        <PulseLoader
                            margin={"4px"}
                            sizeUnit={"px"}
                            size={10}
                            color={"#fff"}
                            loading={loading}
                        />
                    ) : (
                        [failedUpdate ? "Reintentar" : props.button]
                    )}
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        codeJustNow: state.justNowReducer.justNow,
        isPelayo: state.businessCodeReducer.isPelayo,
        customerName: state.userReducer.user.name,
        customerPhone: state.userReducer.user.phone,
        jobTypeState: state.serviceReducer.service.jobType,
        jobAddress: state.serviceReducer.service.address,
        serviceType: state.serviceReducer.service.type,
        price: state.jobReducer.price.amount,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNullCodeJustNow: () => dispatch(setNullCodeJustNow()),
        saveName: (name) => dispatch(saveName(name)),
        saveEmail: (email) => dispatch(saveEmail(email)),
        savePhone: (phone) => dispatch(savePhone(phone)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TermsModal);