import React, { Component } from 'react';

import '../styles/step-tip.css';

class StepTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconLeft: this.props.iconLeft ? this.props.iconLeft  : false,
            iconRight: this.props.iconRight ? this.props.iconRight : false,
            title: this.props.title,
            excerpt: this.props.excerpt,
            linkTitle: this.props.linkTitle ? this.props.linkTitle : false,
            linkUrl: this.props.linkUrl ? this.props.linkUrl : false
        };
    }

    render() {
        const { iconLeft, iconRight, title, excerpt, linkTitle, linkUrl } = this.state;
        return (
            <div className="step-tip hidden-sm hidden-xs">
                <div className="row">
                    <div className="col-sm-6">
                        {iconLeft &&
                            <img src={iconLeft} alt="bright" />
                        }
                    </div>
                    <div className="col-sm-6">
                        {iconRight &&
                            <img className="right" src={iconRight} alt="bright" />
                        }
                    </div>
                </div>
                <p className="title">{title}</p>
                <p className="excerpt">{excerpt}</p>
                {linkTitle && linkUrl && 
                    <a href={linkUrl}>{linkTitle}</a>
                }
            </div>
        );
    }
}

export default StepTip;