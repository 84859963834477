export const typeOrder = type => {
    return {
        type: 'TYPE_ORDER',
        payload: type
    }
}

export const typeOrderSelected = selected => {
    return {
        type: 'TYPE_ORDER_SELECTED',
        payload: selected
    }
}

export const jobType = type => {
    return {
        type: 'JOB_TYPE',
        payload: type
    }
}

export const jobAddress = address => {
    return {
        type: 'JOB_ADDRESS',
        payload: address
    }
}

