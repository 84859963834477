import React, { useState, useEffect } from "react";
import Parse from "parse";
import { getParameterByName } from "../utils/formatter.js";

import Jobin from "jobin-client";

import "../styles/nav-menu-user.css";

export default function NavMenuUser(props) {
    // const [currentUser, setCurrentUser] = useState("");
    const [userName, setUserName] = useState("Nombre usuario");
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    const menu = (
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <title>menu</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M11.9999,15.2422 C11.7439,15.2422 11.4879,15.1442 11.2929,14.9492 L7.0499,10.7072 C6.6599,10.3162 6.6599,9.6832 7.0499,9.2932 C7.4409,8.9022 8.0739,8.9022 8.4639,9.2932 L11.9999,12.8282 L15.5359,9.2932 C15.9259,8.9022 16.5589,8.9022 16.9499,9.2932 C17.3399,9.6832 17.3399,10.3162 16.9499,10.7072 L12.7069,14.9492 C12.5119,15.1442 12.2559,15.2422 11.9999,15.2422"
                    fill="#B0B0B0"
                />
            </g>
        </svg>
    );

    const toggleMenu = () => {
        setCollapsed(!collapsed);
    };

    const logOut = () => {
        Jobin.Client.logOut().then(result => {
            if (result.status === "ok") window.location.href = getParameterByName("business") != "null" ?"/?business="+ getParameterByName("business"):"/";
        });
    };

    const bodyClickHandler = e => {
        if (!e.target.classList.contains("no-collapse")) setCollapsed(false);
    };

    useEffect(() => {
        let didCancel = false;

        Parse.User.currentAsync().then(
            user => {
                if (user) {
                    if (!didCancel) {
                        // setCurrentUser(user);
                        setUserName(user.get("Name"));
                        setLoading(true);
                    }
                } else {
                    if (!didCancel) {
                        // setCurrentUser("");
                        setLoading(false);
                    }
                }
            },
            () => {
                if (!didCancel) {
                    // setCurrentUser("");
                    setLoading(false);
                }
            }
        );

        document.body.addEventListener("click", bodyClickHandler, false);
        // unmount
        return () => {
            didCancel = true;
            document.body.removeEventListener("click", bodyClickHandler, false);
        };
    }, []);

    return (
        <React.Fragment>
            {loading && (
                <li className={collapsed ? "nav-menu collapsed" : "nav-menu"}>
                    <button
                        className="dropdown-toggle no-collapse"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        onClick={toggleMenu}
                    >
                        {userName} <span>{menu}</span>
                    </button>
                    <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        className="hidden-lg hidden-md hidden-sm"
                    >
                        <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <path
                                d="M18.8281,17.8467 C17.4241,15.5427 14.8951,13.9997 12.0001,13.9997 C9.1041,13.9997 6.5761,15.5427 5.1721,17.8467 C3.8211,16.2727 3.0001,14.2327 3.0001,11.9997 C3.0001,7.0367 7.0371,2.9997 12.0001,2.9997 C16.9631,2.9997 21.0001,7.0367 21.0001,11.9997 C21.0001,14.2327 20.1791,16.2727 18.8281,17.8467 M12.0001,0.9997 C5.9251,0.9997 1.0001,5.9247 1.0001,11.9997 C1.0001,15.0757 2.2661,17.8537 4.3011,19.8497 C6.2851,21.7967 9.0011,22.9997 12.0001,22.9997 C14.9991,22.9997 17.7151,21.7967 19.6991,19.8497 C21.7341,17.8537 23.0001,15.0757 23.0001,11.9997 C23.0001,5.9247 18.0751,0.9997 12.0001,0.9997"
                                fill="#444444"
                            />
                            <path
                                d="M12,5 C9.791,5 8,6.791 8,9 C8,11.209 9.791,13 12,13 C14.209,13 16,11.209 16,9 C16,6.791 14.209,5 12,5"
                                fill="#444444"
                            />
                        </g>
                    </svg>
                    {collapsed && (
                        <ul className="dropdown-menu no-collapse">
                            <li className="no-collapse">
                                <button
                                    className="no-collapse"
                                    onClick={logOut}
                                >
                                    Cerrar sesión
                                </button>
                            </li>
                        </ul>
                    )}
                </li>
            )}
        </React.Fragment>
    );
}
