import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../styles/breadcrumb.css";

let _items = [
    {
        name: "Tipo de servicio",
        pathname: "/"
    }
];

export default class BreadCrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: _items,
            active: false
        };
    }

    componentDidMount() {
        // checks if the element already exist inside the array
        const pathname = this.props.history.location.pathname;
        let noRepeat = true;
        _items.forEach((item, index) => {
            if (pathname === item.pathname) {
                _items.length = index + 1;
                noRepeat = false;
            }
        });

        this.setState({
            items: _items
        });

        if (noRepeat) {
            let name = "";

            switch (pathname) {
                case "/description":
                    name = "Detalles";
                    break;
                case "/picture":
                    name = "Fotos";
                    break;
                case "/location":
                    name = "Ubicación";
                    break;
                case "/urgency":
                    name = "Urgencia";
                    break;
                default:
                    name = "";
            }

            if (name !== "") {
                const itemHelper = {
                    name: name,
                    pathname: pathname
                };
                this.setState(prevState => ({
                    items: [...prevState.items, itemHelper]
                }));
            }
        }
    }

    componentWillUnmount() {
        _items = this.state.items;
    }

    render() {
        const { items } = this.state;
        return (
            <div className="breadcrumb hidden-xs">
                <ul>
                    {items.map((item, index) => {
                        return (
                            <li
                                key={`${item.name}-${index}`}
                                className={
                                    items.length - 1 === index
                                        ? "breadcrumb-item active"
                                        : "breadcrumb-item"
                                }
                            >
                                <Link
                                    replace
                                    to={{
                                        pathname: item.pathname,
                                        search: this.props.location.search,
                                        state: {
                                            inner: true
                                        }
                                    }}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
