import { createStore, combineReducers } from 'redux';
import justNowReducer from './reducers/justNowReducer';
import businessCodeReducer from './reducers/businessCodeReducer';
import userReducer from './reducers/userReducer';
import jobReducer from './reducers/jobReducer';
import serviceReducer from './reducers/serviceReducer';

function saveLocalStorage(state){
    try {
        const serializedState = JSON.stringify(state);
    } catch (error) {
        console.log(error);
    }
}

function loadFromLocalStorage(){
    try {
        const serializedState = localStorage.getItem('wolly_state');
        if(serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (error) {
        console.log(error);
        return undefined;
    }
}

const reducers = combineReducers({
    justNowReducer,
    businessCodeReducer,
    userReducer,
    jobReducer,
    serviceReducer
});

const persistedState = loadFromLocalStorage();

const store = createStore(reducers, persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => saveLocalStorage(store.getState()));

export default store;