import React, { Component } from "react";
import Parse from "parse";
import { optimizeImage, uploadImage } from "../utils/image.js";
import { getParameterByName } from "../utils/formatter.js";
import { selectBusinessColor } from "../utils/image.js";
import Jobin from "jobin-client";

import Nav from "../components/Nav";
import StepTip from "../components/StepTip";
import StepControlls from "../components/StepControlls";
import BreadCrumb from "../components/BreadCrumb";
import { connect } from 'react-redux';
import { setNullCodeJustNow } from '../store/actions/justNowActions';
import "../styles/service-urgency.css";

import stepIconLeft from "../assets/step-icon-left.svg";

class ServiceUrgency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.selectedOption,
            error: this.props.error,
            disabledBtn: this.props.disabledBtn,
            loading: false
        };
    }

    handleChange = e => {
        this.setState({
            selectedOption: e.target.value,
            disabledBtn: false
        });
    };

    update = childData => {
        this.props.mixpanel.track("Paso 6 completado");

        // push state to parent to save the content
        this.props.update(childData);
    };

    createJob = business => {
        
        const user =
            business && business.get("User")
                ? business.get("User")
                : this.props.currentUser;
        const props = this.props;

        let imgUrlPromise = [];
        if (props.jobInfo.picture.files.length > 0) {
            imgUrlPromise = props.jobInfo.picture.files.map(file => {
                const base64Promise = optimizeImage(file);
                return base64Promise.then(
                    base64 => {
                        // randomly generate a new file name
                        const fileName =
                            "upload-request-" +
                            Math.floor(Math.random() * 90000) +
                            10000 +
                            ".jpg";
                        return uploadImage(fileName, base64).catch(() => {
                            return undefined;
                        });
                    },
                    () => {
                        return undefined;
                    }
                );
            });
        }

        const createJobPromise = Promise.all(imgUrlPromise).then(imgUrl => {
            props.mixpanel.alias(user.id);
            props.mixpanel.identify(props.mixpanel.get_distinct_id());
            props.mixpanel.people.set({
                $name: user.get("Name"),
                $phone: user.get("Phone"),
                $email: user.get("email"),
                "Nº de trabajos": user.get("Tasks"),
                Nivel: user.get("Level")
            });
            props.mixpanel.track("Registro nombre completado");
            return Parse.Cloud.run("createJob", {
                userId: user.id,
                verticalId: props.jobInfo.category.categoryId,
                verticalType: props.jobInfo.category.categoryType,
                subCategoryId:
                    props.jobInfo.category.subCategoryId !== ""
                        ? props.jobInfo.category.subCategoryId
                        : undefined,
                description: props.jobInfo.description.message,
                images: imgUrl.length > 0 ? imgUrl : undefined,
                latitud: props.jobInfo.locate.center.lat,
                longitud: props.jobInfo.locate.center.lng,
                priority: props.jobInfo.urgency.selectedOption,
                fromIntegration: "web",
                businessId: business ? business.id : undefined,
                state: business ? "abierto" : undefined,
                jobTypeState: props.jobTypeState
            });
        });

        createJobPromise.then(
            () => {
                props.mixpanel.track("Registro completado");
                props.mixpanel.track("Trabajo guardado");
                const clickId = getParameterByName("eid");
                if (clickId) {
                    var xhr = new XMLHttpRequest();
                    xhr.open(
                        "GET",
                        `https://eat.emmasolutions.net/postback?eid=${clickId}`,
                        false
                    );
                    xhr.send(null);
                    if (xhr.status === 200) console.log("emma postback ok");
                }

                this.setState({
                    disabledBtn: false,
                    loading: false
                });

                props.history.push({
                    pathname: "summary",
                    search: props.location.search,
                    state: {
                        inner: true
                    }
                });
            },
            err => {
                this.setState({
                    disabledBtn: false,
                    loading: false
                });
                // checks if the error is caused by no jobers or if its just an internal error
                if (
                    err
                        .toString()
                        .split(" ")
                        .includes("NO_JOBERS")
                )
                    this.props.showModal({ step: 6 });
                else this.props.showModal({ step: 5 });
            }
        );
    };

    checkBusiness = async () => {
        const businessId = getParameterByName("business");
        const businessQuery = new Parse.Query("Business");
        businessQuery.include("User");
        businessQuery.equalTo("objectId", businessId);
        const business = await businessQuery.first();
        return business;
    };

    showModal = async () => {
        const business = await this.checkBusiness();

        if ((this.props.currentUser && this.props.currentUser.has("Name")) || (business && business.get("User"))) {
            this.setState({
                disabledBtn: true,
                loading: true
            });
            this.createJob(business);
        } else {
            this.props.mixpanel.track("Ir a log in / registro");
            this.props.mixpanel.track("Iniciar verificacion telefono");
            // push state to parent to save the content
            this.props.showModal({ step: 1 });
        }
    };

    componentDidMount() {
        if (!(this.props.location.state && "inner" in this.props.location.state))
        Jobin.Client.logOut().then(result => {
            if (result.status === "ok")  this.props.history.replace(getParameterByName("business") != "null" ?"/?business="+ getParameterByName("business"):"/");
        });
    }

    render() {
        const { selectedOption } = this.state;
        return (
            <div id="service-urgency" className="section">
                <Nav />
                <div className="margin-top-72px">
                    <BreadCrumb
                        history={this.props.history}
                        location={this.props.location}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="title">
                                    ¿Para cuando lo necesitas?
                                </h4>
                                <p>
                                    Selecciona la opción que mejor se adapte a
                                    ti
                                </p>
                                <div className="content">
                                    <ul className="urgency-list">
                                        <li>
                                            <input
                                                type="radio"
                                                id="urgency-item-0"
                                                name="urgency-item"
                                                value="urgent"
                                                className="urgency-item native-hidden"
                                                checked={
                                                    selectedOption === "urgent"
                                                }
                                                onChange={this.handleChange}
                                            />
                                            <label htmlFor="urgency-item-0">
                                                <svg
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <defs>
                                                        <path
                                                            d="M8.0001,-0.0003 L2.9041,13.9997 L6.9081,13.9997 L3.2691,23.9997 L7.2681,23.9997 L21.6921,7.9997 L17.0881,7.9997 L20.0001,-0.0003 L8.0001,-0.0003 Z M9.4001,2.0007 L17.1431,2.0007 L14.2321,9.9997 L17.1961,9.9997 L6.3791,22.0007 L6.1251,22.0007 L9.7641,12.0007 L5.7611,12.0007 L9.4001,2.0007 Z"
                                                            id="path-0"
                                                        />
                                                    </defs>
                                                    <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <mask
                                                            id="mask-0"
                                                            fill="white"
                                                        >
                                                            <use xlinkHref="#path-0" />
                                                        </mask>
                                                        <g />
                                                        <g
                                                            mask="url(#mask-0)"
                                                            fill={
                                                                selectedOption ===
                                                                "urgent"
                                                                    ? selectBusinessColor(getParameterByName("business"))
                                                                    : "#D2D2D2"
                                                            }
                                                        >
                                                            <rect
                                                                x="0"
                                                                y="0"
                                                                width="24"
                                                                height="24"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                                <p>Es urgente</p>
                                                <sub>
                                                    Esta opción es solo para
                                                    trabajos que necesites en el
                                                    momento
                                                </sub>
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="radio"
                                                id="urgency-item-1"
                                                name="urgency-item"
                                                value="normal"
                                                className="urgency-item native-hidden"
                                                onChange={this.handleChange}
                                            />
                                            <label htmlFor="urgency-item-1">
                                                <svg
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <defs>
                                                        {selectedOption ===
                                                        "normal" ? (
                                                            <path
                                                                d="M10,20 C4.4775,20 0,15.5225 0,10 C0,4.4775 4.4775,0 10,0 C15.5225,0 20,4.4775 20,10 C20,15.5225 15.5225,20 10,20 Z M10,18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,5.39762708 14.6023729,1.66666667 10,1.66666667 C5.39762708,1.66666667 1.66666667,5.39762708 1.66666667,10 C1.66666667,14.6023729 5.39762708,18.3333333 10,18.3333333 Z M14.1666667,10 C14.1666667,12.3010417 12.3010417,14.1666667 10,14.1666667 C7.69895833,14.1666667 5.83333333,12.3010417 5.83333333,10 C5.83333333,7.69895833 7.69895833,5.83333333 10,5.83333333 C12.3010417,5.83333333 14.1666667,7.69895833 14.1666667,10 Z"
                                                                id="path-1"
                                                            />
                                                        ) : (
                                                            <path
                                                                d="M10,20 C4.4775,20 0,15.5225 0,10 C0,4.4775 4.4775,0 10,0 C15.5225,0 20,4.4775 20,10 C20,15.5225 15.5225,20 10,20 Z M10,18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,5.39762708 14.6023729,1.66666667 10,1.66666667 C5.39762708,1.66666667 1.66666667,5.39762708 1.66666667,10 C1.66666667,14.6023729 5.39762708,18.3333333 10,18.3333333 Z"
                                                                id="path-1"
                                                            />
                                                        )}
                                                    </defs>
                                                    <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <g transform="translate(0.000000, -14.000000)">
                                                            <g transform="translate(0.000000, 14.000000)">
                                                                <mask
                                                                    id="mask-1"
                                                                    fill="white"
                                                                >
                                                                    <use xlinkHref="#path-1" />
                                                                </mask>
                                                                <g />
                                                                <g
                                                                    mask="url(#mask-1)"
                                                                    fillRule="evenodd"
                                                                    fill={
                                                                        selectedOption ===
                                                                        "normal"
                                                                            ? selectBusinessColor(getParameterByName("business"))
                                                                            : "#d2d2d2"
                                                                    }
                                                                >
                                                                    <rect
                                                                        x="0"
                                                                        y="0"
                                                                        width="20"
                                                                        height="20"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <p>En unos días</p>
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="radio"
                                                id="urgency-item-2"
                                                name="urgency-item"
                                                value="target"
                                                className="urgency-item native-hidden"
                                                onChange={this.handleChange}
                                            />
                                            <label htmlFor="urgency-item-2">
                                                <svg
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <defs>
                                                        {selectedOption ===
                                                        "target" ? (
                                                            <path
                                                                d="M10,20 C4.4775,20 0,15.5225 0,10 C0,4.4775 4.4775,0 10,0 C15.5225,0 20,4.4775 20,10 C20,15.5225 15.5225,20 10,20 Z M10,18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,5.39762708 14.6023729,1.66666667 10,1.66666667 C5.39762708,1.66666667 1.66666667,5.39762708 1.66666667,10 C1.66666667,14.6023729 5.39762708,18.3333333 10,18.3333333 Z M14.1666667,10 C14.1666667,12.3010417 12.3010417,14.1666667 10,14.1666667 C7.69895833,14.1666667 5.83333333,12.3010417 5.83333333,10 C5.83333333,7.69895833 7.69895833,5.83333333 10,5.83333333 C12.3010417,5.83333333 14.1666667,7.69895833 14.1666667,10 Z"
                                                                id="path-2"
                                                            />
                                                        ) : (
                                                            <path
                                                                d="M10,20 C4.4775,20 0,15.5225 0,10 C0,4.4775 4.4775,0 10,0 C15.5225,0 20,4.4775 20,10 C20,15.5225 15.5225,20 10,20 Z M10,18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,5.39762708 14.6023729,1.66666667 10,1.66666667 C5.39762708,1.66666667 1.66666667,5.39762708 1.66666667,10 C1.66666667,14.6023729 5.39762708,18.3333333 10,18.3333333 Z"
                                                                id="path-2"
                                                            />
                                                        )}
                                                    </defs>
                                                    <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <g transform="translate(0.000000, -14.000000)">
                                                            <g transform="translate(0.000000, 14.000000)">
                                                                <mask
                                                                    id="mask-2"
                                                                    fill="white"
                                                                >
                                                                    <use xlinkHref="#path-2" />
                                                                </mask>
                                                                <g />
                                                                <g
                                                                    mask="url(#mask-2)"
                                                                    fillRule="evenodd"
                                                                    fill={
                                                                        selectedOption ===
                                                                        "target"
                                                                            ? selectBusinessColor(getParameterByName("business"))
                                                                            : "#d2d2d2"
                                                                    }
                                                                >
                                                                    <rect
                                                                        x="0"
                                                                        y="0"
                                                                        width="20"
                                                                        height="20"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <p>En unos meses</p>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-4">
                                <StepTip
                                    iconLeft={stepIconLeft}
                                    title="Intenta ser lo más exacto posible"
                                    excerpt="Si conoces la fecha en la que necesites el servicio puedes escribirlo en la descripción de la solicitud."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <StepControlls
                    history={this.props.history}
                    location={this.props.location}
                    page={this.props.step}
                    isLastPage={true}
                    disabled={this.state.disabledBtn}
                    nextTrigger={selectedOption === null}
                    loadTrigger={this.state.loading}
                    prev="location"
                    cState={this.state}
                    update={this.update}
                    showModal={this.showModal}
                    loading={this.state.loading}
                />
                <img
                    src="https://pixel.mathtag.com/event/img?mt_id=1443639&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                    width="1"
                    height="1"
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        codeJustNow: state.justNowReducer.justNow,
        isPelayo: state.businessCodeReducer.isPelayo,
        jobTypeState: state.serviceReducer.service.jobType,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNullCodeJustNow: () => dispatch(setNullCodeJustNow())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ServiceUrgency);