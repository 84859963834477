export const selectService = [
    {
        id: "fixed",
        name: "Servicio a precio cerrado",
        selected: false
    },
    {
        id: "showLead",
        name: "Elige un tipo de presupuesto:",
        selected: false
    },
];

export const selectLead = [
    {
        id: "leadThree",
        name: "Pedir 3 presupuestos",
        selected: false
    },
    {
        id: "leadOne",
        name: "Pedir 1 presupuestos",
        selected: false
    }
];
