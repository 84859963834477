import React from "react";

export default function JobinProModal(props) {
    return (
        <div className="modal jobinPro">
            <h4 className="title">{props.title}</h4>
            <p className="excerpt">{props.excerpt}</p>
            <div className="one-row-button">
                <button
                    type="button"
                    onClick={() => {
                        props.history.push({
                            pathname: "/"
                        });
                    }}
                >
                    {props.button}
                </button>
            </div>
        </div>
    );
}
