const initialState = {
    service: {
        type: null,
        typeSelected: false,
        jobType: null,
        address: null
    }
}

function reducer(state = initialState, action){
    switch(action.type){
        case 'TYPE_ORDER':
            return {
                ...state,
                service: {
                    ...state.service,
                    type: action.payload
                }
            }
        case 'TYPE_ORDER_SELECTED':
            return {
                ...state,
                service: {
                    ...state.service,
                    typeSelected: action.payload
                }
            }
        case 'JOB_TYPE':
            return {
                ...state,
                service: {
                    ...state.service,
                    jobType: action.payload
                }
            }
        case 'JOB_ADDRESS':
            return {
                ...state,
                service: {
                    ...state.service,
                    address: action.payload
                }
            }
        default:
            return state;
    }
}

export default reducer;