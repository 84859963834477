import mixpanelObj from "mixpanel-browser";


//PROD
const mixpanelKey = "5c340c20788397dd23776aa440386b8f";
///////

//TEST
// const mixpanelKey = "1012a8b26cc42ef90845cffc85b65bdd";
/**/

export function mixpanelInit() {
    mixpanelObj.init(mixpanelKey);
}

export default function mixpanel() {
    return mixpanelObj;
}
