const initialState = {
    user: {}
}

function reducer(state = initialState, action){
    switch(action.type){
        case 'SAVE_PHONE':
            return {
                ...state,
                user: {
                    ...state.user,
                    phone: action.payload
                }
            }
        case 'SAVE_NAME':
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload
                }
            }
        case 'SAVE_EMAIL':
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.payload
                }
            }
        case 'CURRENT_USER':
            return {
                ...state,
                currentUser: action.payload
            }
        case 'SAVE_OBJECTID':
            return {
                ...state,
                user: {
                    ...state.user,
                    objectId: action.payload
                }
            }
        case 'HAS_EMAIL':
            return {
                ...state,
                user: {
                    ...state.user,
                    hasEmail: action.payload
                }
            }
        default:
            return state;
    }
}

export default reducer;