export const jobRouter = (data) => {
    return {
        type: 'JOB_ROUTER',
        payload: data
    }
}

export const jobCategoryGuildSubcategory = data => {
    return {
        type: 'JOB_CATEGORY_GUILD_SUBCATEGORY',
        payload: data
    }
}

export const jobDescription = data => {
    return {
        type: 'JOB_DESCRIPTION',
        payload: data
    }
}

export const jobPrice = data => {
    return {
        type: 'JOB_PRICE',
        payload: data
    }
}

export const jobPriceExtra = data => {
    return {
        type: 'JOB_PRICE_EXTRAS',
        payload: data
    }
}

export const jobPriceSelected = data => {
    return {
        type: 'JOB_PRICE_SELECTED',
        payload: data
    }
}

export const jobAvailability = data => {
    return {
        type: 'JOB_AVAILABILITY',
        payload: data
    }
}