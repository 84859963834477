import React, { useEffect } from "react";

import Nav from "../components/Nav";
import StepTip from "../components/StepTip";
import StepControlls from "../components/StepControlls";

import "../styles/service-summary.css";
import Jobin from "jobin-client";

import stepIconLeft from "../assets/step-icon-left-2.svg";
import stepIconRight from "../assets/step-icon-right-2.svg";
import publishIcon from "../assets/summary-publish.svg";
import likeIcon from "../assets/summary-like.svg";
import payIcon from "../assets/summary-pay.svg";
import { connect } from 'react-redux';
import { getParameterByName } from "../utils/formatter.js";


function ServiceSummary(props) {
    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
        Jobin.Client.logOut().then(result => {
            if (result.status === "ok")  window.location.href =getParameterByName("business") != "null" ?"/?business="+ getParameterByName("business"):"/";
        });
    }, []);

    const update = () => {
            props.update();
            props.update();

        if(props.isPelayo === true){
            localStorage.removeItem('Parse/ExxNZxyGaH3MmDYWIwKmg6okGr3VWySlxp4Dk16P/currentUser');
            window.location.reload();
        }
    };
    const firstTimeList = (
        <React.Fragment>
            <div className="first-time-item">
                <div className="img-container">
                    <img src={publishIcon} alt="publica" />
                </div>
                <div className="excerpt">
                    <h4 className="title">Publica tu solicitud</h4>
                    <p>
                        Una vez publicada recibirás hasta 3 presupuestos de
                        nuestros profesionales.
                    </p>
                </div>
            </div>
            <div className="first-time-item">
                <div className="img-container">
                    <img src={likeIcon} alt="escoge" />
                </div>
                <div className="excerpt">
                    <h4 className="title">Escoge a tu profesional</h4>
                    <p>
                        Compara precios, valoraciones y experiencia para elegir
                        al profesional que mejor se ajusta a tus necesidades.
                    </p>
                </div>
            </div>
            <div className="first-time-item">
                <div className="img-container">
                    <img src={payIcon} alt="paga" />
                </div>
                <div className="excerpt">
                    <h4 className="title">Paga y Valora</h4>
                    <p>
                        Una vez resuelta tu solicitud, paga al profesional por
                        la app y déjale una valoración.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );

    const firstTime = (
        <div className="first-time">
            <p className="title">¿Primera vez que usas Wolly?</p>
            <p className="excerpt">
                Echa un vistazo para saber como consiguer a tu profesional ideal
                en un tiempo record.
            </p>
            {firstTimeList}
        </div>
    );

    const help = (
        <div className="help">
            <p className="title">¿Necesitas ayuda?</p>
            <p className="excerpt">
                Inicia un Chat en vivo o llama al 91 060 18 25
            </p>
            <p>Nuestro horario es de lunes a viernes de 9:00 a 18:00 horas.</p>
        </div>
    );

 

    return (
        <div id="service-summary" className="section">
            <Nav />
            <div className="margin-top-72px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="title">¡Solicitud publicada!</h4>
                            <p className="excerpt">
                                Nuestros profesionales revisarán tu solicitud y
                                se pondrán en contacto contigo en menos de 24
                                horas.
                            </p>
                            <div className="content">
                                {firstTime}
                                {help}
                            </div>
                        </div>
                        <div className="col-md-offset-1 col-md-4">
                            <StepTip
                                iconLeft={stepIconLeft}
                                iconRight={stepIconRight}
                                title="Garantía Wolly"
                                excerpt="Gracias a nuestra colaboración con Allianz tu solicitud está cubierta. Para activar el seguro solo tienes que pagar el servicio por Wolly, así de fácil."
                            />
                        </div>
                    </div>
                </div>
            </div>
            <StepControlls
                history={props.history}
                location={props.location}
                update={update}
                nextTitle="Aceptar"
                next="root"
            />
            <img
                src="https://pixel.mathtag.com/event/img?mt_id=1443644&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                width="1"
                height="1"
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        codeJustNow: state.justNowReducer.justNow,
        isPelayo: state.businessCodeReducer.isPelayo
    }
}

const mapDispatchToProps = dispatch => {
    return {
    
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ServiceSummary);
