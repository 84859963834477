function formatRelativeGeneralTime(currDate, jobDate) {
    const hoursDiff = currDate.getHours() - jobDate.getHours();
    if (hoursDiff > 0)
        return `hace ${hoursDiff} hora${hoursDiff === 1 ? "" : "s"}`;

    const minsDiff = currDate.getMinutes() - jobDate.getMinutes();
    if (minsDiff > 0)
        return `hace ${minsDiff} minuto${minsDiff === 1 ? "" : "s"}`;

    return "ahora";
}

function formatRelativeGeneralDate(isoDate, precise) {
    const jobDate = new Date(Date.parse(isoDate));
    const currDate = new Date();

    currDate.setHours(0, 0, 0, 0);
    jobDate.setHours(0, 0, 0, 0);

    const millisDif = currDate.getTime() - jobDate.getTime();
    const daysDif = Math.trunc(millisDif / 86400000); // 1 day = 24 * 3600 * 1000 = 8.640.000 millis

    if (daysDif === 0)
        return precise
            ? formatRelativeGeneralTime(
                  new Date(),
                  new Date(Date.parse(isoDate))
              )
            : "hoy";
    else if (daysDif === 1) return "ayer";
    else return `hace ${daysDif} días`;
}

export function formatRelativeDate(isoDate) {
    return `Subida ${formatRelativeGeneralDate(isoDate, false)}`;
}

export function formatRelativePreciseDate(isoDate) {
    return formatRelativeGeneralDate(isoDate, true);
}

export function getParameterByName(name, url) {
    if ("business" === name && localStorage.getItem(name)) {
        return localStorage.getItem(name); // De primeras intentar cargarlo de localStorage
    }

    return getURLParameterByName(name, url);
}

export function getURLParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";

    const paramResult = decodeURIComponent(results[2].replace(/\+/g, " "));
    const businessLiterals = {
        "diglo": "ZcFC5W6QCB",
    }

    return businessLiterals.hasOwnProperty(paramResult) ? businessLiterals[paramResult] : paramResult;
}

