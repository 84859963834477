export const savePhone = phone =>{
    return {
        type: 'SAVE_PHONE',
        payload: phone
    }
} 

export const saveName = name => {
    return {
        type: 'SAVE_NAME',
        payload: name
    }
}

export const saveEmail = email => {
    return {
        type: 'SAVE_EMAIL',
        payload: email
    }
}

export const currentUser = data => {
    return {
        type: 'CURRENT_USER',
        payload: data
    }
}

export const saveObjectId = objectId => {
    return {
        type: 'SAVE_OBJECTID',
        payload: objectId
    }
}

export const hasEmail = value => {
    return {
        type: 'HAS_EMAIL',
        payload: value
    }
}